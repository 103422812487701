import React, { useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { registerCustomFormat } from './registerCustomFormat'; // Adjust path as necessary

// function spacedToCamelCase(str) {
//   // Trim the string and split it into words
//   const words = str.trim().split(/\s+/);

//   // Transform the first word to lowercase
//   const firstWord = words[0].toLowerCase();

//   // Capitalize the first letter of each subsequent word and join everything
//   const camelCaseStr =
//     firstWord +
//     words
//       .slice(1)
//       .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
//       .join('');

//   return camelCaseStr;
// }

function spacedToLowerCase(str) {
  // Trim the string and split it into words
  const words = str.trim().split(/\s+/);

  // Transform the first word to lowercase
  const firstWord = words[0].toLowerCase();

  // Capitalize the first letter of each subsequent word and join everything
  const camelCaseStr =
    firstWord +
    words
      .slice(1)
      .map((word) => word.charAt(0).toLowerCase() + word.slice(1).toLowerCase())
      .join('');

  return camelCaseStr;
}

const QuillDynamic = (props) => {
  const quillRef = useRef(null);

  useEffect(() => {
    // Register custom tag format
    // Ensure Quill library is available
    // if (typeof Quill !== 'undefined') {
    registerCustomFormat(Quill);
    // }

    // Create and append custom dropdown to the toolbar
    const quill = quillRef.current.getEditor();

    quill.on('text-change', function (delta, oldDelta, source) {
      if (source === 'user') {
        let selection = quill.getSelection();
        if (selection) {
          const format = quill.getFormat(selection.index, selection.length);

          if (format['confusability-firstname']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-firstname': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }

          if (format['confusability-lastname']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-lastname': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }

          if (format['confusability-email']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-email': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }

          if (format['confusability-phone']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-phone': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }

          if (format['confusability-date']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-date': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }

          if (format['confusability-website']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-website': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }

          if (format['confusability-country']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-country': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }

          if (format['confusability-company']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-company': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }

          if (format['confusability-address']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-address': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }
          if (format['confusability-state']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-state': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }

          if (format['confusability-zipcode']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-zipcode': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }

          if (format['confusability-city']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-city': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }

          if (format['confusability-logo']) {
            // Assuming the custom tag affects an entire block (like a paragraph),
            // you should identify the block's start and end to accurately remove its format.

            // Find the line and its actual index
            const [line] = quill.getLine(selection.index);
            if (line) {
              const lineIndex = quill.getIndex(line);
              const lineLength = line.length();

              // Now remove the custom format across the entire line
              quill.formatText(lineIndex, lineLength, {
                'confusability-logo': false
              });

              // Optionally, if you want to ensure it's in a <p> tag,
              // Quill typically wraps unformatted text in <p> by default,
              // but you can explicitly set it if necessary (depends on your Quill setup).
            }
          }
        }
      }
    });

    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('confusability-data-tag', () => {});
    toolbar.addHandler('confusability-firstname', () => {});

    // const checkboxUpdateFromDB = document.createElement('checkbox');

    const customSelect = document.createElement('select');
    customSelect.innerHTML = props.customSelect;
    toolbar.container.appendChild(customSelect);

    customSelect.addEventListener('change', function () {
      const selectedIndex = this.selectedIndex;
      const selectedOption = this.options[selectedIndex];
      const labelText = this.value;
      const value = spacedToLowerCase(selectedOption.text);
      const range = quill.getSelection();
      if (value && range) {
        if (value === 'logo') {
          // Example check for an image URL
          // Insert an image

          quill.insertEmbed(range.index, 'image', selectedOption.value, Quill.sources.USER);
        } else {
          // Delete the selected text
          quill.deleteText(range.index, range.length);

          // Insert new text at the same position
          const insertedText = `${labelText}`;
          quill.insertText(range.index, insertedText);

          // Apply custom format to the newly inserted text
          // if (checkboxUpdateFromDB){
          quill.formatText(range.index, insertedText.length, `confusability-${value}`, true);
          // }
          //
          // quill.formatText(insertedText.length, 1, `p`, true);
        }

        this.value = ''; // Reset the dropdown
      }
    });
  }, [props.customSelect]);

  return <ReactQuill {...props} ref={quillRef} theme="snow" />;
};

export default QuillDynamic;
