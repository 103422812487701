/* eslint-disable */
import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';
import AuthWrapper from 'pages/authentication/AuthWrapper';
import { connect } from 'react-redux';

const privacyContent = `

This Privacy Policy ("Policy") explains how Apellai Inc. ("we," "us," or "our") collects, uses, and protects the personal information of users ("you" or "your") when you access and use our website, confusability.ai ("the Website"). We are committed to safeguarding your privacy and ensuring the security of your personal information. By using the Website, you consent to the practices described in this Policy.

**1. Information We Collect**

**1.1 Personal Information:** We may collect personal information from you when you create an account, make a payment, or communicate with us. This may include your name, email address, postal address, phone number, and payment details. We only collect personal information that is necessary to provide the requested services and process your transactions.

**1.2 Usage Information:** We may collect non-personal information about your use of the Website, such as your IP address, browser type, operating system, and the pages you visit. This information helps us analyze trends, administer the Website, and improve our services.

**2. Use of Information**

**2.1 Service Provision:** We use the personal information you provide to deliver the requested services, process payments, and maintain your account. This includes providing access to the trademark image search engine, processing your search requests, and facilitating customer support.

**2.2 Communication:** We may use your email address or other contact information to communicate with you regarding your account, service updates, promotional offers, or other relevant information. You can opt out of receiving promotional emails by following the instructions provided in the communication.

**2.3 Aggregated Data:** We may aggregate and anonymize the usage information we collect to generate statistical or analytical insights. These insights do not identify individual users and may be used for research, marketing, or improving our services.

**2.4 Legal Compliance:** We may use or disclose your personal information when required by law, legal process, or government authorities. We may also use or disclose your information to enforce our Terms of Service, protect our rights or property, or investigate and prevent fraud or security issues.

**3. Data Security**

We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, disclosure, or alteration. However, please note that no method of transmission over the internet or electronic storage is completely secure. We cannot guarantee the absolute security of your information.

**4. Third-Party Service Providers**

We may engage third-party service providers to assist us in delivering our services or to perform specific functions on our behalf, such as payment processing or data analysis. These providers have access to the personal information needed to perform their functions but are prohibited from using it for any other purpose.

**5. Data Retention**

We retain your personal information for as long as necessary to fulfill the purposes outlined in this Policy unless a longer retention period is required or permitted by law. We will securely delete or anonymize your personal information when it is no longer needed.

**6. Children's Privacy**

The Website is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you become aware that your child has provided us with personal information without your consent, please contact us, and we will take steps to remove the information from our systems.

**7. International Data Transfers**

The personal information we collect may be transferred to and processed in countries other than your own. By using the Website, you consent to the transfer of your personal information to these countries, which may have different data protection laws than your country of residence.

**8. Your Rights**

You have certain rights regarding your personal information, including the right to access, update, or delete the information we hold about you. If you wish to exercise these rights or have any questions or concerns about the processing of your personal information, please contact us using the information provided below.

**9. Changes to the Privacy Policy**

We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes to the Policy will be posted on the Website. We encourage you to review this Policy periodically to stay informed about our privacy practices.

**10. Contact Us**

If you have any questions, comments, or requests regarding this Privacy Policy, please contact us at support@confusability.ai. We will respond to your inquiries as soon as reasonably possible.

Effective date: May 23, 2023

Last updated: June 25, 2023
`;

const Privacy = (props) => {
  return (
    <AuthWrapper removeLogo={props?.auth?.user?.email} large>
      <Container>
        <Box my={4} p={3} borderRadius={8}>
          <Typography variant="h4" component="h1" gutterBottom color="#3f51b5">
            Privacy Policy
          </Typography>
          <Divider />
          <Box mt={2}>
            {privacyContent.split('\n\n').map((paragraph, index) => (
              <Typography key={index} variant="body1" paragraph>
                {paragraph.split('**').map((text, index) => (index % 2 === 1 ? <strong key={index}>{text}</strong> : text))}
              </Typography>
            ))}
          </Box>
        </Box>
      </Container>
    </AuthWrapper>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps)(Privacy);
