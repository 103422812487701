/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardHeader, CardContent, Box, Typography, CardActions, Button } from '@mui/material';
import { connect } from 'react-redux';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { getSubscriptionByID } from 'actions/stripe';
import { subscribeUser } from 'actions/userActions';
import { subscribe } from 'actions/watch';

const WatchSubscribeCard = (props) => {
  const tiers = [
    {
      title: props.popup ? '' : 'Subscribe to',
      subheader: props.popup ? `Please resubscribe to the ${'Watch'} Service to access editing functionality` : 'Watch Service',
      // price: '9.99',
      pricePerYear: '20.00',
      buttonText: '7-day free trial',
      buttonVariant: 'contained',
      description: [
        'Watch list is updated daily.',
        'An email summarizing new results is also sent per your email preferences.',
        'Results can be filtered, reordered, and archived.',
        'Customizable watch reports are automatically produced.'
      ]
    }
  ];
  const [loading, setLoading] = useState(false);

  const subscribeNow = async () => {
    window.open(props?.url, '_blank');
  };

  return (
    !loading && (
      <Container sx={{ width: '100%' }} component="main">
        <Grid sx={{ width: '100%' }} container display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {tiers.map((tier) => (
            <Grid item key={tier.title} xs={12} md={props.popup ? 12 : 4}>
              <Card elevation={props.popup ? 0 : 2}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center', variant: 'subtitle1', color: 'grey' }}
                  subheaderTypographyProps={{ align: 'center', variant: 'h4', fontWeight: 'bold', color: 'black' }}
                />
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 4, width: '100%' }}>
                    <Typography component="h2" variant="h3" color="text.primary">
                      ${tier.pricePerYear}
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ ml: 1 }}>
                      /logo/year
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line, index) => (
                      <Typography component="li" variant="subtitle1" align="left" key={index}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', marginTop: -2 }}>
                  {props.popup && (
                    <Button onClick={props.handleClose} variant={tier.buttonVariant} color="primary">
                      Subscribe Later
                    </Button>
                  )}
                  <CardActions sx={{ justifyContent: 'center' }}>
                    <Button onClick={subscribeNow} variant={tier.buttonVariant} color="primary">
                      Subscribe Now
                    </Button>
                  </CardActions>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    )
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(mapStateToProps)(WatchSubscribeCard);
