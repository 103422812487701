import axiosInstance from '../axiosInstance';

export const subscribe = async (data) => {
  // try {
  //   const response = await axiosInstance.post('/api/user-subscribe', data);
  //   console.log(response.data); // Request submitted successfully
  // } catch (error) {
  //   console.error(error);
  // }
  return data;
};

export const createSession = async (data) => {
  try {
    const response = await axiosInstance.post('/api/create-checkout-session', data);
    console.log('Request submitted successfully', response.data); // Request submitted successfully
  } catch (error) {
    console.error(error);
  }
};

export const cancelSubscription = async (data) => {
  try {
    await axiosInstance.post('/api/cancel-subscription-by-id', data);
  } catch (error) {
    console.error(error);
  }
};
export const cancelWatchSubscription = async (data) => {
  try {
    await axiosInstance.post('/api/cancel-watch-subscription-by-id', data);
  } catch (error) {
    console.error(error);
  }
};

export const getSubscribeURL = async (data) => {
  try {
    const result = await axiosInstance.post('/api/create-checkout-session', data);
    console.log({ result });
    return result;
  } catch (error) {
    console.error({ error });
  }
};

export const getSubscriptionByID = async (data) => {
  try {
    const result = await axiosInstance.post('/api/user-subscription', data);
    console.log(result);
    return result;
  } catch (error) {
    console.error(error);
  }
};

// export const requestDeletion = async (userID) => {
//   try {
//     const response = await axiosInstance.post("/api/request-deletion", { userID });
//     console.log(response.data); // Request submitted successfully
//   } catch (error) {
//     console.error(error);
//   }
// };
