/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Button,
  useMediaQuery,
  Tooltip,
  Chip,
  Grid
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ArchiveIcon from '@mui/icons-material/Inventory';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ReadMore from './ReadMore';
import LightboxGallery from './LightboxGallery';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material';

const DragDropTable = React.memo(
  ({
    columns,
    originalData,
    onChange,
    trademarkToAdd,
    setIframe,
    removeActions,
    archive,
    showArchived,
    disabled,
    handleOpenSubscribeModal
  }) => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const actionType = {
      MOVED: 'moved',
      REMOVED: 'removed',
      ADDED: 'added',
      UNHIDE: 'unhide',
      HIDE: 'hide'
    };

    const [tempRow, setTempRows] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);

    const Label = ({ id }) => {
      return (
        <a
          href="#"
          data-bs-target="#verticalycentered"
          data-bs-toggle="modal"
          onClick={() => {
            setIframe(id);
          }}
        >
          {id}
        </a>
      );
    };

    useEffect(() => {
      setTempRows(originalData);
    }, [originalData]);

    const moveRow = (dragIndex, hoverIndex) => {
      const draggedRow = tempRow[dragIndex];
      const updatedRows = [...tempRow];
      updatedRows.splice(dragIndex, 1);
      updatedRows.splice(hoverIndex, 0, draggedRow);
      setTempRows(updatedRows);
    };

    useEffect(() => {
      const existingRow = tempRow.find((row) => {
        if (row?.['serial-number'] === trademarkToAdd?.['serial-number']) {
          return row;
        }
      });
      if (trademarkToAdd) {
        if (!existingRow) {
          let newData = trademarkToAdd;
          newData.added = true;
          setTempRows((prev) => [newData, ...prev]);
          onChange([newData, ...tempRow], actionType.ADDED);
          toast.success('Item Added Successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          });
        } else {
          toast.error('This item is already in the list.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          });
        }
      }
    }, [trademarkToAdd]);

    const removeItem = (res) => {
      if (disabled) {
        handleOpenSubscribeModal();
      } else {
        const newData = tempRow?.filter((item) => item.id != res['serial-number']);
        setTempRows(newData);
        onChange(newData, actionType.REMOVED);

        toast.success('Item Removed Successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
      }
      //
    };

    const archiveRow = (res) => {
      if (disabled) {
        handleOpenSubscribeModal();
      } else {
        const table = tempRow?.map((item) => {
          if (res?.id == item?.id) {
            if (item?.archived) {
              item.archived = false;
              return item;
            } else {
              toast.success('Archived! You can always unarchive any logo from the Archives list.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
              });
              item.archived = true;
              return item;
            }
          } else {
            return item;
          }
        });
        setTempRows(table);
        onChange(table, res?.archived ? actionType.HIDE : actionType.UNHIDE, res?.id);
      }
    };

    const hideItem = (res) => {
      // setTempRows((current) =>
      //   current?.map((item) => {
      //     if (res?.id == item?.id) {
      //       if (item?.hidden) {
      //         item.hidden = false;
      //         return item;
      //       } else {
      //         item.hidden = true;
      //         return item;
      //       }
      //     } else {
      //       return item;
      //     }
      //   })
      // );
      if (disabled) {
        handleOpenSubscribeModal();
      } else {
        const table = tempRow?.map((item) => {
          if (res?.id == item?.id) {
            if (item?.hidden) {
              item.hidden = false;
              return item;
            } else {
              item.hidden = true;
              return item;
            }
          } else {
            return item;
          }
        });
        setTempRows(table);

        onChange(table, res.hidden ? actionType.HIDE : actionType.UNHIDE, res?.id);
      }
    };

    const TableRowWithDnd = ({ row, index }) => {
      const [, drop] = useDrop({
        accept: 'TableRow',
        hover: (item) => {
          if (item.index !== index) {
            moveRow(item.index, index);
            item.index = index;
          }
        },
        drop: () => {
          //  setRows(tempRow)
          onChange(tempRow, actionType.MOVED);
          // This function will run when the drag is over (item is dropped)
        }
      });

      const [{ isDragging }, drag] = useDrag({
        type: 'TableRow',
        item: { index },
        collect: (monitor) => ({
          isDragging: monitor.isDragging()
        })
      });

      const nestedPropertyAccessor = (obj, path) => {
        const pathParts = path.split('.');
        let value = obj;
        for (const part of pathParts) {
          if (part.includes('[')) {
            const index = parseInt(part.match(/\[(\d+)\]/)[1]);
            value = value[part.split('[')[0]][index];
          } else {
            value = value[part];
          }
          if (!value) break;
        }
        return value;
      };

      const opacity = isDragging || row.hidden ? 0.3 : 1;

      return (
        ((!!row?.archived !== showArchived && archive) || !archive) && (
          <TableRow
            ref={(node) => (!disabled ? drag(drop(node)) : () => null)}
            style={row.isFiltered ? { backgroundColor: 'lightgrey' } : { opacity }}
          >
            <TableCell ref={drag} sx={{ minWidth: '50px' }}>
              {!removeActions && (
                <Grid>
                  {!row.isFiltered && (
                    <Grid>
                      <LongPressIconButton style={{ cursor: 'pointer' }}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          style={{ cursor: 'pointer' }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <OpenWithIcon />
                        </Box>
                      </LongPressIconButton>
                    </Grid>
                  )}
                  {!row?.isFiltered && row?.added && (
                    <Grid>
                      <IconButton
                        display="flex"
                        flexDirection="column"
                        style={{ cursor: 'pointer' }}
                        alignItems="center"
                        onClick={() => setOpenDelete(row)}
                      >
                        {row?.added && (
                          // <Button sx={{ mb: 1, width: 100 }} variant="contained" onClick={() => removeItem(row)}>
                          //   Remove
                          // </Button>
                          <DeleteIcon />
                        )}
                        {/* <Button sx={{ width: 100 }} variant="contained" onClick={() => hideItem(row)}>
                {row?.hidden ? 'Show' : 'Hide'}
              </Button> */}
                      </IconButton>
                    </Grid>
                  )}
                  {!row?.isFiltered && (
                    <Grid>
                      <IconButton
                        display="flex"
                        flexDirection="column"
                        style={{ cursor: 'pointer' }}
                        alignItems="center"
                        onClick={() => hideItem(row)}
                      >
                        {row?.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
              {archive && (
                <Grid>
                  <LongPressIconButton onClick={() => archiveRow(row)} style={{ cursor: 'pointer' }}>
                    <Box display="flex" flexDirection="column" style={{ cursor: 'pointer' }} alignItems="center" justifyContent="center">
                      <Tooltip title={row?.archived ? 'Unarchive' : 'Archive'} placement="top">
                        {row?.archived ? <UnarchiveIcon color={'error'} /> : <ArchiveIcon color={'primary'} />}
                      </Tooltip>
                    </Box>
                  </LongPressIconButton>
                </Grid>
              )}
            </TableCell>
            {columns?.map((column) => {
              const fieldValue = nestedPropertyAccessor(row, column?.field);

              return (
                <TableCell
                  style={{ opacity, textDecoration: row.hidden && 'line-through' }}
                  sx={{ height: 'auto', textAlign: 'center' }}
                  key={`${nestedPropertyAccessor(row, column?.field)}-${column?.field}`}
                >
                  {!row.isFiltered && fieldValue && (
                    <>
                      {column.field === 'id' ? (
                        <Label id={fieldValue} />
                      ) : column.field === 'usClasses' ? (
                        <Grid container sx={{ display: 'flex', maxWidth: '140px', justifyContent: 'center' }}>
                          {fieldValue?.map((data, usClassesIndex) => (
                            <Grid key={usClassesIndex} xs={7} sm={7} md={7} lg={7} sx={{ marginBottom: 1 }}>
                              <Tooltip title={data?.description} placement="top">
                                <Chip label={data?.code} sx={chipStyle} />
                              </Tooltip>
                            </Grid>
                          ))}
                        </Grid>
                      ) : column.field === 'designSearchList' ? (
                        <Grid container sx={{ display: 'flex', maxWidth: '150px', justifyContent: 'center' }}>
                          {fieldValue?.map((data, DesginSearchListIndex) => (
                            <Grid key={DesginSearchListIndex} xs={7} sm={7} md={7} lg={7} sx={{ marginBottom: 1 }}>
                              <Tooltip title={data?.descriptions} placement="top">
                                <Chip label={data?.code} sx={{ ...chipStyle, width: '100px' }} />
                              </Tooltip>
                            </Grid>
                          ))}
                        </Grid>
                      ) : column.field === 'status' ? (
                        <Grid sx={{ marginBottom: 1 }}>
                          <Tooltip title={fieldValue?.extStatusDesc} placement="top">
                            <Chip label={fieldValue?.status} sx={chipStyle} />
                          </Tooltip>
                        </Grid>
                      ) : column.field === 'internationalClasses' ? (
                        <Grid container sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100px' }}>
                          {fieldValue && fieldValue?.length > 0 && (
                            <>
                              {fieldValue?.map((data, internationalIndex) => (
                                <Grid key={internationalIndex} sx={{ marginBottom: 1, display: 'flex', justifyContent: 'center' }}>
                                  <Tooltip title={data?.description} placement="top">
                                    <Chip label={data?.code} sx={chipStyle} />
                                  </Tooltip>
                                </Grid>
                              ))}
                            </>
                          )}
                        </Grid>
                      ) : column.field === 'image-url' ? (
                        <Box container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <LightboxGallery table image={fieldValue} />
                        </Box>
                      ) : (
                        <Box container sx={{ maxWidth: '400px' }}>
                          <ReadMore text={fieldValue?.toString()} cutoff={250} />
                        </Box>
                      )}
                    </>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        )
      );
    };

    // LongPressIconButton component to handle touch and hold event
    const LongPressIconButton = ({ children, ...props }) => {
      const [longPress, setLongPress] = useState(false);

      let longPressTimer;

      const onLongPressStart = () => {
        longPressTimer = setTimeout(() => {
          disabled ? handleOpenSubscribeModal() : setLongPress(true);
        }, 500); // 500ms for long press detection
      };

      const onLongPressEnd = () => {
        clearTimeout(longPressTimer);
        setLongPress(false);
        // setRows(tempRow);
      };

      return (
        <IconButton
          {...props}
          onTouchStart={onLongPressStart}
          onTouchEnd={onLongPressEnd}
          onTouchCancel={onLongPressEnd}
          onMouseDown={onLongPressStart}
          onMouseUp={onLongPressEnd}
          onMouseLeave={onLongPressEnd}
          style={longPress ? { backgroundColor: '#efefef', margin: 0, padding: 0 } : { margin: 0, padding: 0 }}
        >
          {children}
        </IconButton>
      );
    };

    const DeleteConfirmationDialog = ({ title, contentText, handleAgree }) => {
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
      const handleClose = () => {
        setOpenDelete(false);
      };
      const handleAgreeAction = () => {
        handleAgree();
        setOpenDelete(false);
      };
      return (
        <Dialog fullScreen={fullScreen} open={openDelete} onClose={handleClose} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{contentText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleAgreeAction} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      );
    };

    return (
      <DndProvider backend={HTML5Backend}>
        <ToastContainer />
        <DeleteConfirmationDialog
          title={'Delete Row'}
          contentText={'Are you sure you want to delete this row? All deletions are permanent.'}
          handleAgree={() => {
            removeItem(openDelete);
          }}
        />
        <TableContainer component={Paper} sx={{ maxWidth: matchesXs ? '95vw' : '100%', overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* First column with an avatar */}
                {/* <TableCell sx={{ textAlign: 'center' }}>Move</TableCell> */}
                <TableCell sx={{ textAlign: 'center' }}></TableCell>
                {columns?.map((column) => (
                  <TableCell sx={{ textAlign: 'center' }} key={column.field}>
                    {column.header}
                  </TableCell>
                ))}
                {/* Last column for actions */}
              </TableRow>
            </TableHead>
            <TableBody>
              {tempRow?.map((row, index) => (
                <TableRowWithDnd key={row.id} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DndProvider>
    );
  }
);

export default DragDropTable;

const chipStyle = {
  borderWidth: 1,
  cursor: 'help',
  marginLeft: 0,
  fontSize: '11px',
  padding: '0px',
  minWidth: '45px',
  borderRadius: '15px',
  height: '25px'
};
