import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, FormControl, Grid, Box } from '@mui/material';

const TermsAndConditionsModal = ({ open, onClose, onAgree }) => {
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  // Check if the user has scrolled to the end of the terms and conditions and agreed to them
  const handleScrollToAgree = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    // const reachedBottom = scrollTop + clientHeight === scrollHeight;
    const isNearBottom = Math.abs(scrollTop + clientHeight - scrollHeight) < 1; // 1px margin

    setAgreedToTerms(isNearBottom);
  };

  const handleAgree = () => {
    onAgree();
    onClose();
  };

  const termsContent = `
  Terms of Service


These Terms of Service ("Terms") govern your use of the website confusability.ai ("the Website") provided by Apellai Inc. ("we," "us," or "our"). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using the Website.

1. Description of Service


The Website provides a search engine for trademark images made available by the United States Patent and Trademark Office ("the Service"). The Service allows users to search for and access a set of registered and applicant trademark images for a fee. Users can be located anywhere globally, subject to compliance with applicable laws and regulations.

2. User Accounts


2.1 Account Creation: In order to access and use the Service, you must create an account. You agree to provide accurate, complete, and up-to-date information during the registration process. You are solely responsible for maintaining the confidentiality of your account information, including your username and password. You agree to notify us immediately of any unauthorized use of your account.


2.2 Account Usage: You are solely responsible for all activities that occur under your account. You agree not to share your account credentials with others or allow unauthorized access to the Service. You acknowledge that we are not responsible for any losses or damages arising from unauthorized use of your account.

3. Fees and Payments


3.1 Pricing: The use of the Service is subject to the payment of applicable fees. The pricing for the Service is clearly stated on the Website, and you agree to pay the fees associated with your selected plan or usage.


3.2 Payment Method: We may offer various payment methods for your convenience. You agree to provide accurate and valid payment information and authorize us to charge the specified amount to your selected payment method.

3.3 Subscription and Billing: If you choose a subscription-based plan, you agree to recurring billing at the specified intervals, unless you cancel your subscription prior to the next billing cycle. You are responsible for timely cancellation if you no longer wish to continue the subscription.


3.4 Refunds: We may have refund policies in place, as outlined on the Website. Refund eligibility will be determined in accordance with these policies.

4. Intellectual Property


4.1 Trademarks: The trademark images made available through the Service are protected by intellectual property laws. All trademarks displayed on the Website are the property of their respective owners. You may not use, reproduce, distribute, or modify these trademarks without obtaining the necessary permissions.


4.2 User Content: By using the Service, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, and distribute any content you submit or display through the Website for the purpose of providing and promoting the Service.

5. Limitation of Liability


5.1 Disclaimer: The Service is provided on an "as-is" and "as available" basis. We make no warranties or representations regarding the accuracy, reliability, or completeness of the Service. We disclaim all warranties, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose, and non-infringement.


5.2 Limitation of Liability: To the maximum extent permitted by applicable law, we shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Service, even if we have been advised of the possibility of such damages.

6. Governing Law and Dispute Resolution


These Terms shall be governed by and construed in accordance with the laws of the United States, without regard to its conflict of laws principles. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in the United States.

7. Modification of Terms


We reserve the right to modify these Terms at any time without prior notice. Any changes to the Terms will be effective immediately upon posting on the Website. Your continued use of the Service after the posting of revised Terms constitutes your acceptance of such changes.

8. Termination


We may, in our sole discretion, terminate or suspend your access to the Service without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the Service will cease immediately.

9. Severability


If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.

10. Entire Agreement


These Terms constitute the entire agreement between you and us regarding your use of the Service and supersede all prior agreements and understandings.

If you have any questions or concerns regarding these Terms, please contact us at support@confusability.ai.
  `;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Terms and Conditions</DialogTitle>
      <DialogContent dividers onScroll={handleScrollToAgree} style={{ maxHeight: '500px', overflowY: 'scroll' }}>
        {termsContent.split('\n\n').map((paragraph, index) => (
          // todo check break line
          <Typography key={index} variant="body1" paragraph>
            <br></br>
            {paragraph + '\n'}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Box sx={{ bgcolor: agreedToTerms ? 'green' : 'red', width: 85, height: 8, borderRadius: '7px' }} />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" fontSize="0.75rem">
                {agreedToTerms ? 'Agreed' : 'Scroll to Agree'}
              </Typography>
            </Grid>
          </Grid>
        </FormControl>
        <Button variant="contained" onClick={handleAgree} disabled={!agreedToTerms}>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsModal;
