/* eslint-disable */
import React from 'react';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import CookieBanner from 'components/CookieBanner';
import { useTheme, useMediaQuery, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axiosInstance from './axiosInstance'; // Adjust the path accordingly
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from 'actions/authActions';
import { store } from 'store';
const cookie = localStorage.getItem('cookie');
const token = localStorage.getItem('jwtToken');
const sessionID = localStorage.getItem('sessionID');

// Set the default token for all requests
axiosInstance.defaults.headers.common['Authorization'] = `${token}`;
if (sessionID) {
  axiosInstance.defaults.headers.common['sessionID'] = sessionID;
}

const SessionDialog = ({ title, contentText }) => {
  const dispatch = useDispatch();
  const isSessionTimeout = useSelector((state) => state.session.isSessionTimeout);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    store.dispatch(logoutUser());
  };

  return (
    <Dialog fullScreen={fullScreen} open={isSessionTimeout} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          I understand
        </Button>
      </DialogActions>
    </Dialog>
  );
};

console.error = () => {};
console.warn = () => {};
console.info = () => {};

const App = () => (
  <ThemeCustomization>
    <ScrollTop>
      {!cookie && <CookieBanner />}
      <Routes />
      <SessionDialog
        title="Logging Out of This Session"
        contentText="You have logged in somewhere else. You will now be logged out of this session."
      />
    </ScrollTop>
  </ThemeCustomization>
);

export default App;
