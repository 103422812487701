/* eslint-disable */
import PropTypes from 'prop-types';

// material-ui
import { Box, Grid } from '@mui/material';

// project import
import AuthCard from './AuthCard';
import Logo from 'components/Logo';
import AuthFooter from 'components/cards/AuthFooter';

// assets
import AuthBackground from 'assets/images/auth/AuthBackground';

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({ children, large, removeLogo }) => (
  <Box>
    <AuthBackground />
    <Grid
      container
      direction="column"
      justifyContent="center"
      sx={{
        minHeight: removeLogo ? 'calc(100vh - 212px)' : '100vh',
        width: 'auto'
      }}
    >
      {!removeLogo && (
        <Grid item xs={12} sx={{ ml: 3, mt: 3 }}>
          <Logo />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: removeLogo ? 'calc(97vh - 132px)' : 'calc(100vh - 112px)' } }}
        >
          <Grid item>
            <AuthCard large={large}>{children}</AuthCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
        <AuthFooter />
      </Grid>
    </Grid>
  </Box>
);

AuthWrapper.propTypes = {
  children: PropTypes.node
};

export default AuthWrapper;
