/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardHeader, CardContent, Box, Typography, CardActions, Button } from '@mui/material';
import { connect } from 'react-redux';
import { getUser, subscribeUser } from '../../actions/userActions';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { getSubscriptionByID } from 'actions/stripe';

const Subscriptions = (props) => {
  const [selectedOption, setSelectedOption] = useState('monthly');
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  const tiers = [
    {
      title: props.popup ? '' : 'Welcome to the',
      subheader: props.popup
        ? `Please resubscribe to the ${'Search'} Engine to access editing functionality`
        : 'Search Engine at confusability.ai!',
      price: '50',
      pricePerYear: '500',
      buttonText: '7-day free trial',
      buttonVariant: 'contained',
      description: [
        'Unlimited searches.',
        'Database updated daily.',
        'Includes trademark applications that are not yet registered.',
        'Edit knockout search reports easily.',
        'Customize report design.',
        'Save and view entire workspace history.'
      ]
    }
  ];

  function generateRandomId() {
    const id = Math.random().toString().substring(2, 14) + new Date().getTime();
    return id;
  }
  const freeTrial = async () => {
    let data = {
      userID: props.auth.user.id,
      subscriptionID: generateRandomId(),
      type: 'trial_' + selectedOption
    };
    await subscribeUser(data);
  };

  const skipTheFreeTrial = async () => {
    let data = {
      userID: props.auth.user.id,
      subscriptionID: generateRandomId(),
      type: 'premium_' + selectedOption
    };
    await subscribeUser(data);
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      if (props.auth?.user) {
        setLoading(true);
        const subscriptionID = props.auth?.user?.user?.subscription?.subscriptionID;

        if (subscriptionID) {
          try {
            const result = await getSubscriptionByID({ id: subscriptionID });
            if (result?.data) {
              setSubscription(result.data);
            } else {
              console.error('Subscription data not found');
            }
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.error('Error fetching subscription:', error);
          }
        } else {
          setLoading(false);
        }
      }
    };

    fetchSubscription();
  }, [props.auth?.user?.user?.subscription?.subscriptionID]); // Dependency array

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    !loading && (
      <Container sx={{ width: '100%' }} component="main">
        <Grid sx={{ width: '100%' }} container display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {tiers.map((tier) => (
            <Grid item key={tier.title} xs={12} md={props.popup ? 12 : 4}>
              <Card elevation={props.popup ? 0 : 2}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center', variant: 'subtitle1', color: 'grey' }}
                  subheaderTypographyProps={{ align: 'center', variant: 'h4', fontWeight: 'bold', color: 'black' }}
                />
                <CardContent>
                  <FormControl style={{ width: '100%' }} component="fieldset">
                    <RadioGroup
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', mb: 2, width: '100%' }}
                      row
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      {!(subscription && subscription.type.includes('monthly') && !subscription.cancelledByUser) && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2, width: '100%' }}>
                          <FormControlLabel value="monthly" control={<Radio color="primary" />} />
                          <Typography component="h2" variant="h3" color="text.primary">
                            ${tier.price}
                          </Typography>
                          <Typography variant="h6" color="text.secondary" sx={{ ml: 1 }}>
                            /month
                          </Typography>
                        </Box>
                      )}
                      {!(subscription && subscription.type.includes('yearly') && !subscription.cancelledByUser) && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2, width: '100%' }}>
                          <FormControlLabel value="yearly" control={<Radio color="primary" />} />
                          <Typography component="h2" variant="h3" color="text.primary">
                            ${tier.pricePerYear}
                          </Typography>
                          <Typography variant="h6" color="text.secondary" sx={{ ml: 1 }}>
                            /year
                          </Typography>
                        </Box>
                      )}
                    </RadioGroup>
                  </FormControl>
                  <ul>
                    {tier.description.map((line, index) => (
                      <Typography component="li" variant="subtitle1" align="left" key={index}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', marginTop: -2 }}>
                  {props.popup && (
                    <Button onClick={props.handleClose} variant={tier.buttonVariant} color="primary">
                      Subscribe Later
                    </Button>
                  )}
                  {!props.auth.user.alreadyUsedTrial && (
                    <Button onClick={freeTrial} variant={tier.buttonVariant} color="primary">
                      {tier.buttonText}
                    </Button>
                  )}
                  <CardActions sx={{ justifyContent: 'center' }}>
                    <Button onClick={skipTheFreeTrial} variant={tier.buttonVariant} color="primary">
                      {!props.auth.user.alreadyUsedTrial ? 'Skip the free trial' : 'Subscribe Now'}
                    </Button>
                  </CardActions>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    )
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(mapStateToProps)(Subscriptions);
