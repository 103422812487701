/* eslint-disable */
import axiosInstance from '../axiosInstance';
import { createBrowserHistory } from 'history';
import jwt_decode from 'jwt-decode';
import { setCurrentUser } from './authActions';
import setAuthToken from '../utils/setAuthToken';
import { store } from 'store/index';
import { getSubscribeURL } from './stripe';

export const addUser = async (userData) => {
  await axiosInstance.post('/api/user-add', userData);
};

export const updateUser = async (userData) => {
  try {
    const res = await axiosInstance.post('/api/user-update', userData);
    const { token } = res.data;
    const decoded = jwt_decode(token);
    const e = await axiosInstance.post('/api/check-subscription', {
      userID: decoded.id
    });
    decoded.subscription = true;
    // window.location.href = '/profile';
    localStorage.setItem('jwtToken', token);
    return res;
  } catch (err) {
    return err;
  }
};
// https://buy.stripe.com/test_5kAcPgbY0fXI9Zm9AB
export const subscribeUser = async (data) => {
  try {
    // const res = await axiosInstance.post('/api/user-subscribe', data);
    // console.log("res", res)
    const userRes = await axiosInstance.post('/api/get-user', { _id: data.userID });
    // const { token } = userRes.data;
    // let decoded = jwt_decode(token);
    // const e = await axiosInstance.post('/api/check-subscription', {
    //   userID: decoded.id
    // });

    console.log('type', data.type);
    if (data.type == 'trial_monthly') {
      let dataPost = {
        plan: '7_DAY_TRIAL_ONE_MONTH',
        userID: data.userID
      };
      console.log(dataPost);
      const res = await getSubscribeURL(dataPost);
      console.log('url', res);
      window.open(res.data.url);
    }
    if (data.type == 'trial_yearly') {
      let dataPost = {
        plan: '7_DAY_TRIAL_ONE_YEAR',
        userID: data.userID
      };
      console.log(dataPost);
      const res = await getSubscribeURL(dataPost);
      console.log('url', res);
      window.open(res.data.url);
    }
    if (data.type == 'premium_monthly') {
      let dataPost = {
        plan: 'ONE_MONTH_PREMIUM',
        userID: data.userID
      };
      console.log(dataPost);
      const url = await getSubscribeURL(dataPost);
      console.log('url', url);
      window.open(url.data.url);
    } else if (data.type == 'premium_yearly') {
      let dataPost = {
        plan: 'ONE_YEAR_PREMIUM',
        userID: data.userID
      };
      console.log(dataPost);
      const url = await getSubscribeURL(dataPost);
      console.log('url', url);
      window.open(url.data.url);
    }

    return userRes;
  } catch (err) {
    console.log(err);
  }
};

export const getUser = async (userData) => {
  try {
    const res = await axiosInstance.post('/api/get-user', userData);
    const { token } = res.data;
    let decoded = jwt_decode(token);
    const e = await axiosInstance.post('/api/check-subscription', {
      userID: decoded.id
    });
    localStorage.setItem('jwtToken', token);
    decoded = { ...decoded, ...e.data };
    store.dispatch(setCurrentUser(decoded));
    setAuthToken(token);
    return res;
  } catch (err) {
    return {};
  }
};

export const deleteUser = async (_id) => {
  try {
    const res = await axiosInstance.post('/api/user-delete', _id);
    console.log(res);
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};
