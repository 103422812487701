/* eslint-disable */
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { connect } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, CardContent, ClickAwayListener, Grid, IconButton, Paper, Popper, Stack, Typography } from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// assets
import avatar1 from 'assets/images/users/avatar-1.png';
import { LogoutOutlined } from '@ant-design/icons';
import { logoutUser } from 'actions/authActions';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = (props) => {
  const theme = useTheme();

  const handleLogout = async () => {
    // logout
    props.logoutUser();
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = 'grey.300';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          {/* <Avatar alt="profile user" sx={{ width: 32, height: 32 }}>
      {capitalizeFirstLetter(props.auth.user.firstName[0])}
    </Avatar> */}

          <Typography variant="subtitle1">
            {capitalizeFirstLetter(props.auth.user.firstName)} {capitalizeFirstLetter(props.auth.user.lastName)}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Box sx={{ height: '200px' }}>
                {' '}
                {/* Set the height here */}
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    width: 200,
                    minWidth: 200,
                    maxWidth: 200
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Grid sx={{ padding: 2 }} container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <Stack direction="row" spacing={1.25} alignItems="center">
                          {/* <Avatar alt="profile user" sx={{ width: 32, height: 32 }}>
      {capitalizeFirstLetter(props.auth.user.firstName[0])}
    </Avatar> */}
                          <Stack>
                            <Typography variant="h6">Log Out</Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <IconButton size="large" color="secondary" onClick={handleLogout}>
                          <LogoutOutlined />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ClickAwayListener>
                </Paper>
              </Box>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth
});
export default connect(mapStateToProps, { logoutUser })(Profile);

const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};
