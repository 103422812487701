import Home from 'pages/home/index';
import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
export default function EmailVerified() {
  useEffect(() => {
    navigate();
  }, []);

  const navigate = async () => {
    await toast.success('Email verified successfully', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light'
    });
    // location.href = '/'
  };

  return (
    <>
      <ToastContainer />
      <Home />
    </>
  );
}
