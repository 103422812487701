import axiosInstance from '../axiosInstance';

export const addReport = async (data) => {
  try {
    const res = await axiosInstance.post('/api/report-add', data);
    return res;
  } catch (error) {
    console.log(error);
    throw error; // You can choose to throw the error to handle it further up the chain or return a specific error response.
  }
};

export const updateReport = async (data) => {
  try {
    const res = await axiosInstance.post('/api/report-update', data);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getReports = async (data) => {
  try {
    const res = await axiosInstance.post('/api/user-reports', data);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
