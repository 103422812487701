import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout/index';
import EmailVerified from 'pages/email-verified/index';
import TermsOfService from 'pages/terms-of-service/index';
import PrivacyPolicy from 'pages/privacy-policy/index';

// render - login

const VerifyEmailPage = Loadable(lazy(() => import('pages/verify-email')));
const Contact = Loadable(lazy(() => import('pages/contact-us')));
// ==============================|| AUTH ROUTING ||============================== //

const VerificationRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/contact',
      element: <Contact />
    },
    {
      path: 'email-verified',
      element: <EmailVerified />
    },
    {
      path: '*',
      element: <VerifyEmailPage />
    },
    {
      path: 'terms-of-service',
      element: <TermsOfService />
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />
    },
    {
      path: '/',
      element: <VerifyEmailPage />
    },
    {
      path: 'verify-email',
      element: <VerifyEmailPage />
    }
  ]
};

export default VerificationRoutes;
