/* eslint-disable */
import axiosInstance from '../axiosInstance';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from './types';
import { setShowProceedPopup } from 'store/sessionSlice';

export const registerUser = (userData) => async (dispatch) => {
  try {
    await axiosInstance.post('/api/register', userData);
    window.location.href = '/';
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const resendVerificationEmail = async (email) => {
  try {
    const response = await axiosInstance.post(`/api/resend-verification-email`, { email: email });
    return response;
  } catch (error) {
    console.log(error);
    // throw new Error(error.response.data.message || 'Something went wrong.');
  }
};

export const changePassword = async (userData) => {
  const res = await axiosInstance.post('/api/change-password', userData);

  return res;
};

export const loginUser = (userData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axiosInstance.post('/api/login', userData);
      if (res.data.proceedWithLogin && !userData.proceedWithLogin) {
        // Show an alert with the message from the server
        dispatch(
          setShowProceedPopup({
            show: true,
            message: 'Your custom message here'
          })
        );
      } else {
        dispatch(
          setShowProceedPopup({
            show: false,
            message: 'Your custom message here'
          })
        );
        userData.proceedWithLogin = true;
        res = await axiosInstance.post('/api/login', userData);
        const { token, sessionID } = res.data;
        let decoded = jwt_decode(token);
        const result = await axiosInstance.post('/api/check-subscription', {
          userID: decoded.id
        });

        decoded = { ...decoded, ...result.data };
        dispatch(setCurrentUser(decoded));

        localStorage.setItem('jwtToken', token);
        localStorage.setItem('sessionID', sessionID);
        setAuthToken(token);
        resolve(res);
      }
    } catch (err) {
      const error = 'Email or password is incorrect';
      dispatch({
        type: GET_ERRORS,
        payload: error
      });
    }
  });
};

export const forgetPassword = (email) => async (dispatch) => {
  try {
    const res = await axiosInstance.post('/api/reset-password', { email: email });

    return res;
  } catch (err) {
    const error = '';
    dispatch({
      type: GET_ERRORS,
      payload: error
    });
  }
};

export const enterCodeVerification = (token, verificationCode) => async (dispatch) => {
  try {
    const data = { verificationCode: verificationCode, token: token };
    const res = await axiosInstance.post('/api/enter-code', data);
    return res;
  } catch (err) {
    const error = '';
    dispatch({
      type: GET_ERRORS,
      payload: error
    });
  }
};

export const resetPassword = (token, newPassword) => async (dispatch) => {
  try {
    const res = await axiosInstance.post('/api/save-password', { token, newPassword });

    window.location.href = '/';
    return res;
  } catch (err) {
    const error = 'Invalid or expired token';
    dispatch({
      type: GET_ERRORS,
      payload: error
    });
  }
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

export const logoutUser = () => async (dispatch) => {
  try {
    await axiosInstance.post('/api/logout');
  } catch (error) {
    console.log(error);
  }
  localStorage.removeItem('jwtToken');
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  window.location.href = '/';
};
