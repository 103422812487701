/* eslint-disable */
import MainCard from 'components/MainCard';
import React from 'react';
import { Button, Box, Typography, Grid } from '@mui/material';
import { addImage } from 'actions/imageActions';
import ModernImageInput from 'components/ImageInput';
import { connect } from 'react-redux';
// import Link from '@mui/material/Link'
// import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import AuthFooter from 'components/cards/AuthFooter';
import axiosInstance from 'axiosInstance';
import { addReport } from 'actions/reportActions';
import { updateSearchByID } from 'actions/searchesActions';

function Home(props) {
  const [image, setImage] = React.useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const uploadFile = async () => {
    if (croppedImageUrl) {
      try {
        setLoading(true);
        let formData = new FormData();
        formData.append(
          'file',
          dataURLtoFile(croppedImageUrl.result ? croppedImageUrl.result : croppedImageUrl, `${new Date().getTime()}.`)
        );

        let data = {
          formData: formData,
          trademark: { userID: props.auth.user.id, selectedClasses: [], label: formatDateToCustomFormat() }
        };

        const result = await addImage(data).catch((err) => {
          setLoading(false);
        });

        setLoading(true);

        if (result?.data?.data?._id) {
          await saveReport(result?.data?.data);
          navigate('/search-results/' + result?.data?.data?._id);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      alert('Please ensure that you upload an image');
    }
  };

  const saveReport = async (search) => {
    try {
      await axiosInstance
        .post('/api/report-data', { _id: props.auth.user.reportID })
        .then(async (res) => {
          let firstPartReplacedData = res.data.data.part1;
          let secondPartReplacedData = res.data.data.part2;

          const urlPattern = /https:\/\/confusability-assets\.s3\.us-east-2\.amazonaws\.com\/apple-touch-icon\.png/g;
          const regexDate = /<confusability-date([^>]*)>([\s\S]*?)<\/confusability-date>/g;
          const regexFirstName = /<confusability-firstname([^>]*)>([\s\S]*?)<\/confusability-firstname>/g;
          const regexLastName = /<confusability-lastname([^>]*)>([\s\S]*?)<\/confusability-lastname>/g;
          const regexCompany = /<confusability-company([^>]*)>([\s\S]*?)<\/confusability-company>/g;
          const regexCountry = /<confusability-country([^>]*)>([\s\S]*?)<\/confusability-country>/g;
          const regexCity = /<confusability-city([^>]*)>([\s\S]*?)<\/confusability-city>/g;
          const regexZipCode = /<confusability-zipcode([^>]*)>([\s\S]*?)<\/confusability-zipcode>/g;
          const regexAddress = /<confusability-address([^>]*)>([\s\S]*?)<\/confusability-address>/g;
          const regexPhone = /<confusability-phone([^>]*)>([\s\S]*?)<\/confusability-phone>/g;
          const regexEmail = /<confusability-email([^>]*)>([\s\S]*?)<\/confusability-email>/g;
          const regexWebsite = /<confusability-website([^>]*)>([\s\S]*?)<\/confusability-website>/g;
          const regexState = /<confusability-state([^>]*)>([\s\S]*?)<\/confusability-state>/g;
          const USER_FIRSTNAME_VALUE = props.auth.user.firstName;
          const USER_EMAIL_VALUE = props.auth.user.email;
          const USER_LASTNAME_VALUE = props.auth.user.lastName;
          const USER_PHONE_VALUE = props.auth.user.phone;
          const USER_COUNTRY_VALUE = props.auth.user.country;
          const USER_ADDRESS_VALUE = props.auth.user.address;
          const WEBSITE_VALUE = props.auth.user.website;
          const CITY_VALUE = props.auth.user.city;
          const STATE_VALUE = props.auth.user.state;
          const COMPANY_VALUE = props.auth.user.company;
          const ZIPCODE_VALUE = props.auth.user.zipCode;
          const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ];

          const date = new Date();
          const monthName = months[date.getMonth()];

          const year = date.getFullYear();

          const dayOfMonth = date.getDate().toString().padStart(2, '0');
          const DD_VALUE = dayOfMonth;
          const YYYY_VALUE = year;
          const MONTH_NAME_VALUE = monthName;
          try {
            firstPartReplacedData = firstPartReplacedData.replace(urlPattern, search?.imageURL || '');

            firstPartReplacedData = firstPartReplacedData.replace(
              'https://confusability-assets.s3.us-east-2.amazonaws.com/apple-touch-icon.png',
              search?.imageURL || ''
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              'https://confusability-assets.s3.us-east-2.amazonaws.com/apple-touch-icon.png',
              search?.imageURL
            );

            firstPartReplacedData = firstPartReplacedData.replace(
              regexDate,
              `<confusability-date$1>${MONTH_NAME_VALUE} ${DD_VALUE}, ${YYYY_VALUE}</confusability-date>` || ''
            );

            firstPartReplacedData = firstPartReplacedData.replace(
              regexCity,
              `<confusability-city$1>${CITY_VALUE}</confusability-city>` || ''
            );

            firstPartReplacedData = firstPartReplacedData.replace(
              regexState,
              `<confusability-state$1>${STATE_VALUE}</confusability-state>` || ''
            );

            firstPartReplacedData = firstPartReplacedData.replace(
              regexZipCode,
              `<confusability-zipcode$1>${ZIPCODE_VALUE}</confusability-zipcode>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexFirstName,
              `<confusability-firstname$1>${USER_FIRSTNAME_VALUE}</confusability-firstname>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexLastName,
              `<confusability-lastname$1>${USER_LASTNAME_VALUE}</confusability-lastname>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexCompany,
              `<confusability-company$1>${COMPANY_VALUE}</confusability-company>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexCountry,
              `<confusability-country$1>${USER_COUNTRY_VALUE}</confusability-country>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexAddress,
              `<confusability-address$1>${USER_ADDRESS_VALUE}</confusability-address>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexPhone,
              `<confusability-phone$1>${USER_PHONE_VALUE}</confusability-phone>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexEmail,
              `<confusability-email$1>${USER_EMAIL_VALUE}</confusability-email>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexWebsite,
              `<confusability-website$1>${WEBSITE_VALUE}</confusability-website>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexDate,
              `<confusability-date$1>${MONTH_NAME_VALUE} ${DD_VALUE}, ${YYYY_VALUE}</confusability-date>` || ''
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexDate,
              `<confusability-date$1>${MONTH_NAME_VALUE} ${DD_VALUE}, ${YYYY_VALUE}</confusability-date>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(regexCity, `<confusability-city$1>${CITY_VALUE}</confusability-city>`);
            secondPartReplacedData = secondPartReplacedData.replace(
              regexZipCode,
              `<confusability-zipcode$1>${ZIPCODE_VALUE}</confusability-zipcode>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexFirstName,
              `<confusability-firstname$1>${USER_FIRSTNAME_VALUE}</confusability-firstname>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexState,
              `<confusability-state$1>${STATE_VALUE}</confusability-state>` || ''
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexLastName,
              `<confusability-lastname$1>${USER_LASTNAME_VALUE}</confusability-lastname>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexCompany,
              `<confusability-company$1>${COMPANY_VALUE}</confusability-company>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexCountry,
              `<confusability-country$1>${USER_COUNTRY_VALUE}</confusability-country>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexAddress,
              `<confusability-address$1>${USER_ADDRESS_VALUE}</confusability-address>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexPhone,
              `<confusability-phone$1>${USER_PHONE_VALUE}</confusability-phone>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexEmail,
              `<confusability-email$1>${USER_EMAIL_VALUE}</confusability-email>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexWebsite,
              `<confusability-website$1>${WEBSITE_VALUE}</confusability-website>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexDate,
              `<confusability-date$1>${MONTH_NAME_VALUE} ${DD_VALUE}, ${YYYY_VALUE}</confusability-date>`
            );
          } catch (error) {}

          const data = {
            ...res.data,
            data: { ...res.data.data, part1: firstPartReplacedData, part2: secondPartReplacedData },
            searchID: search._id
          };

          const reportResult = await addReport(data);

          const resData = reportResult.data.data;

          const updateRes = await updateSearchByID(search._id, { reportID: resData._id });
          setLoading(false);
        })
        .catch((err) => {});
    } catch (error) {}
  };

  const formatDateToCustomFormat = () => {
    const date = new Date();
    const timestamp = date.getTime();
    return timestamp;
  };

  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const tempExt = mime.split('/');
    const extension = tempExt.length > 1 ? tempExt[1] : tempExt[0];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename + extension, { type: mime });
  };

  return (
    <>
      <MainCard>
        <Box>
          {/* <Breadcrumbs aria-label="breadcrumb"><Link underline="hover" color="inherit" href="/">  {t('appName')}</Link><Typography color="text.primary">{t('search')}</Typography>
          </Breadcrumbs> */}
        </Box>
        <Box sx={{ mt: 3, mb: 7, width: '100%' }}>
          <Typography variant="h5">The trademark design search engine</Typography>
          <Typography variant="span">Drop your logo and get a knockout search report in a few seconds.</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70%' }}>
          <Box sx={{ width: '100%', maxWidth: '700px' }}>
            <Box sx={{ width: '100%', maxWidth: '700px', display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
              {' '}
              <Button
                onClick={() => {
                  setCroppedImageUrl(null);
                }}
              >
                {' '}
                X
              </Button>
            </Box>
            <ModernImageInput
              setImage={setImage}
              croppedImageUrl={croppedImageUrl}
              setCroppedImageUrl={setCroppedImageUrl}
              image={image?.result ? image?.result : image}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 5 }}>
              {' '}
              <Button
                disabled={loading}
                sx={{ width: '100%', maxWidth: '700px' }}
                variant="contained"
                onClick={uploadFile}
                endIcon={<SearchIcon />}
              >
                {' '}
                Submit
              </Button>
            </Box>
            <p style={{ textAlign: 'center', fontSize: '10px' }}>
              {' '}
              Information provided here does not constitute legal advice or create an attorney-client relationship. Please consult with a
              trademark attorney before making important decisions.
            </p>
          </Box>
        </Box>
      </MainCard>
      <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
        <AuthFooter />
      </Grid>
    </>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { addImage })(Home);
