/* eslint-disable */
import React, { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Box, Typography, useTheme, styled, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ReactEasyCrop from 'react-easy-crop';
import 'react-easy-crop/react-easy-crop.css';
import CropperComponent from './croper';

const ModernImageInput = ({ setImage, image, croppedImageUrl, setCroppedImageUrl }) => {
  const [openModal, setOpenModal] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      const isImage = file.type.startsWith('image/');
      const isSvg = file.type === 'image/svg+xml';

      if (isImage && !isSvg) {
        reader.onloadend = () => {
          const imageUrl = reader.result; // Get the URL of the selected image
          setImage(imageUrl); // Update the image state with the URL
        };
        reader.readAsDataURL(file);
        setOpenModal(true);
      } else {
        alert('Please select an image file.');
      }
    },
    [setImage]
  );

  const theme = useTheme();

  return (
    <div style={{ position: 'relative', borderRadius: 10 }}>
      <Dropzone onDrop={onDrop} accept="image/*">
        {({ getRootProps, getInputProps }) => (
          <StyledDropzoneBox {...getRootProps()}>
            {croppedImageUrl ? (
              <img
                src={croppedImageUrl}
                alt="uploaded"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: 'inherit'
                }}
              />
            ) : (
              <>
                <input {...getInputProps()} />
                <StyledCloudUploadIcon fontSize="50px" />
                <br />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: theme.palette.primary.main }}>
                  <Typography variant="body1" sx={{ textAlign: 'center', ml: 1, fontSize: '18px' }}>
                    Upload your trademark logo
                  </Typography>
                </Box>
                <br />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ml: 1 }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    or drag and drop here
                  </Typography>
                </Box>
              </>
            )}
          </StyledDropzoneBox>
        )}
      </Dropzone>

      {/* The cropping area modal */}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>Selecting an area crops the image.</DialogTitle>
        <DialogContent sx={{ minWidth: '40vw', minHeight: '35vh' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%' }}>
            <CropperComponent
              croppedImageUrl={croppedImageUrl}
              setCroppedImageUrl={setCroppedImageUrl}
              image={image}
              setImage={setImage}
              setOpenModal={setOpenModal}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <Button variant="contained" onClick={() => setOpenModal(false)}>
              Continue
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModernImageInput;

const StyledDropzoneBox = styled(Box)(({ theme }) => ({
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'auto',
  height: 400,
  borderRadius: 10,
  border: '2px dashed #bfbfbf',
  cursor: 'pointer',
  overflow: 'hidden',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease-in-out',
  color: '#cccccc',
  '&:hover': {
    // transform: 'scale(1.05)',
    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.light,
    bgColor: theme.palette.primary.light,
    '& $StyledCloudUploadIcon': {
      transform: 'scale(1.2)'
    }
  }
}));

const StyledCloudUploadIcon = styled(CloudUploadIcon)({
  mb: 1,
  fontSize: 90,
  color: 'inherit',
  transition: 'transform 0.3s ease-in-out'
});
