import axiosInstance from '../axiosInstance';

export const getTrademarks = async ({ id, imageURL, pageIndex }) => {
  let body = { id, imageURL, pageIndex };
  try {
    const res = await axiosInstance.post('/api/trademarksv2', body, { timeout: 1000000 });
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};
