let isCustomFormatRegistered = false;

export function registerCustomFormat(Quill) {
  if (isCustomFormatRegistered) {
    return;
  }

  const Inline = Quill.import('blots/inline');
  class CustomTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-data-tag', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-data-tag');
    }
  }
  // Define the blot name and tag name for Quill to use
  CustomTag.blotName = 'confusability-data-tag';
  CustomTag.tagName = 'confusability-data-tag'; // Using a <span> tag for the custom inline format
  // Register the custom tag with Quill
  Quill.register(CustomTag, true);

  class FirstNameTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-firstname', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-firstname');
    }
  }
  // Define the blot name and tag name for Quill to use
  FirstNameTag.blotName = 'confusability-firstname';
  FirstNameTag.tagName = 'confusability-firstname'; // Using a <span> tag for the custom inline format
  // Register the custom tag with Quill
  Quill.register(FirstNameTag, true);

  class LastNameTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-lastname', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-lastname');
    }
  }

  // Define the blot name and tag name for Quill to use
  LastNameTag.blotName = 'confusability-lastname';
  LastNameTag.tagName = 'confusability-lastname'; // Using a <span> tag for the custom inline format
  Quill.register(LastNameTag, true);

  class DateTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-date', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-date');
    }
  }
  // Define the blot name and tag name for Quill to use
  DateTag.blotName = 'confusability-date';
  DateTag.tagName = 'confusability-date'; // Using a <span> tag for the custom inline format
  Quill.register(DateTag, true);

  class EmailTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-email', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-email');
    }
  }
  // Define the blot name and tag name for Quill to use
  EmailTag.blotName = 'confusability-email';
  EmailTag.tagName = 'confusability-email'; // Using a <span> tag for the custom inline format
  Quill.register(EmailTag, true);

  class CountryTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-country', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-country');
    }
  }
  // Define the blot name and tag name for Quill to use
  CountryTag.blotName = 'confusability-country';
  CountryTag.tagName = 'confusability-country'; // Using a <span> tag for the custom inline format
  Quill.register(CountryTag, true);

  class PhoneTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-phone', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-phone');
    }
  }
  // Define the blot name and tag name for Quill to use
  PhoneTag.blotName = 'confusability-phone';
  PhoneTag.tagName = 'confusability-phone'; // Using a <span> tag for the custom inline format
  Quill.register(PhoneTag, true);

  class AddressTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-address', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-address');
    }
  }
  // Define the blot name and tag name for Quill to use
  AddressTag.blotName = 'confusability-address';
  AddressTag.tagName = 'confusability-address'; // Using a <span> tag for the custom inline format
  Quill.register(AddressTag, true);

  class StateTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-state', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-state');
    }
  }
  // Define the blot name and tag name for Quill to use
  StateTag.blotName = 'confusability-state';
  StateTag.tagName = 'confusability-state'; // Using a <span> tag for the custom inline format
  Quill.register(StateTag, true);

  class WebsiteTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-website', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-website');
    }
  }
  // Define the blot name and tag name for Quill to use
  WebsiteTag.blotName = 'confusability-website';
  WebsiteTag.tagName = 'confusability-website'; // Using a <span> tag for the custom inline format
  Quill.register(WebsiteTag, true);

  class ZipCodeTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-zipcode', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-zipcode');
    }
  }
  // Define the blot name and tag name for Quill to use
  ZipCodeTag.blotName = 'confusability-zipcode';
  ZipCodeTag.tagName = 'confusability-zipcode'; // Using a <span> tag for the custom inline format
  Quill.register(ZipCodeTag, true);

  class CompanyTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-company', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-company');
    }
  }
  // Define the blot name and tag name for Quill to use
  CompanyTag.blotName = 'confusability-company';
  CompanyTag.tagName = 'confusability-company'; // Using a <span> tag for the custom inline format
  Quill.register(CompanyTag, true);

  class CityTag extends Inline {
    static create(value) {
      let node = super.create();
      // Use a specific attribute to store the value
      node.setAttribute('confusability-city', value);
      // Apply any custom styling here
      //node.setAttribute('style', 'background-color:white;');
      return node;
    }

    static value(node) {
      // Retrieve the stored value from the attribute
      return node.getAttribute('confusability-city');
    }
  }
  // Define the blot name and tag name for Quill to use
  CityTag.blotName = 'confusability-city';
  CityTag.tagName = 'confusability-city'; // Using a <span> tag for the custom inline format
  Quill.register(CityTag, true);

  let BlockEmbed = Quill.import('blots/block/embed');

  class LogoTag extends BlockEmbed {
    static create(value) {
      // Create a node for the blot
      let node = super.create();

      // Construct the inner HTML structure
      node.innerHTML = `<p><strong><img src="${value}"></strong></p>`;

      return node;
    }

    static value(node) {
      // Assuming you want to extract the image src as the value
      let img = node.querySelector('img');
      return img ? img.src : '';
    }
  }

  LogoTag.blotName = 'customImage';
  LogoTag.tagName = 'div'; // Use a div to encapsulate the custom content

  // Register the custom blot with Quill
  Quill.register(LogoTag, true);

  // Set the flag to prevent future re-registration
  isCustomFormatRegistered = true;
}
