import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
// const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const ForgetPassword = Loadable(lazy(() => import('pages/forget-password')));
const TermsOfService = Loadable(lazy(() => import('pages/terms-of-service')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/privacy-policy')));
const VerifyEmailPage = Loadable(lazy(() => import('pages/verify-email')));
const LandingPage = Loadable(lazy(() => import('pages/landing-page')));
const Contact = Loadable(lazy(() => import('pages/contact-us')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '*',
      element: <LandingPage />
    },
    {
      path: '/reset-password',
      element: <ForgetPassword />
    },
    {
      path: '/',
      element: <LandingPage />
    },
    {
      path: 'login',
      element: <LandingPage />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: 'terms-of-service',
      element: <TermsOfService />
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />
    },
    {
      path: 'verify-email',
      element: <VerifyEmailPage />
    },
    {
      path: 'contact',
      element: <Contact />
    }
  ]
};

export default LoginRoutes;
