// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
// import Search from './Search';
import Profile from './Profile';
// import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      {/* {!matchesXs && <Search />} */}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {/* 
      <IconButton
        component={Link}
        href="https://github.com/codedthemes/mantis-free-react-admin-template"
        target="_blank"
        disableRipple
        color="secondary"
        title="Download Free Version"
        sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
      >
        <GithubOutlined />
      </IconButton> */}

      {/* <Notification /> */}
      <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>{<Profile />}</Box>
      {/* {matchesXs && <MobileSection />} */}
    </>
  );
};

export default HeaderContent;
