import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Paper, Typography, Box, useMediaQuery } from '@mui/material';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import step_2 from '../assets/images/step-2_results.png';
import GIF from '../assets/gif/SignInPage.gif';

const items = [
  {
    type: 'gif',
    image: GIF,
    caption: 'The image search engine for trademark logos in the United States.',
    caption2: 'Unlimited searches. Updated daily. Automated & customizable knockout search reports. $50/month. 7-day free trial.'
  }
  // {
  //   type: 'image',
  //   image: step_2,
  //   caption: 'A professional tool for trademarks lawyers.'
  // },

  // {
  //   caption: 'The trademark logo search engine.',
  //   subCaption: 'The database of registered trademark images in the United States.',
  //   texts: [
  //     'Unlimited searches',
  //     'Database updated daily',
  //     'Includes applications not yet registered',
  //     'Edit reports easily',
  //     'Customize report design',
  //     'Flat $10/month fee'
  //   ]
  // }
  // Add more items as needed
];

const OwlCarouselComponent = () => {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const options = {
    // loop: true,
    margin: 10,
    responsiveClass: true,
    autoplay: true,
    // nav: !matchDownSM, // Enable navigation arrows
    dots: !matchDownSM,
    responsive: {
      0: {
        items: 1
      }
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <OwlCarousel className="owl-theme" {...options}>
        {items.map((item, index) =>
          item.type === 'image' ? (
            <div key={index} className="item">
              <Typography sx={{ fontSize: '22px', fontWeight: 'bolder', margin: 1 }}>{item.caption}</Typography>
              <Paper elevation={0} sx={{ margin: 1 }}>
                <img style={{ borderRadius: '10px' }} src={item.image} alt={item.caption} />
              </Paper>
            </div>
          ) : item.type === 'gif' ? (
            <div key={index} className="item">
              <Typography sx={{ fontSize: '22px', fontWeight: 'bolder', margin: 1 }}>{item.caption}</Typography>
              <Typography sx={{ fontSize: '18px', margin: 1 }}>{item.caption2}</Typography>
              <Paper elevation={0} sx={{ margin: 1 }}>
                <img style={{ borderRadius: '10px' }} src={item.image} alt={item.caption} />
              </Paper>
            </div>
          ) : (
            <div key={index} className="item">
              <Typography sx={{ fontSize: '22px', fontWeight: 'bolder', margin: 1 }}>{item.caption}</Typography>
              <Typography sx={{ fontSize: '18px', fontWeight: 'bold', margin: 1 }}>{item.subCaption}</Typography>
              <Box elevation={1} sx={{ margin: 1, padding: 2 }}>
                {!matchDownSM && (
                  <>
                    <br />
                    <br />
                  </>
                )}
                {item.texts.map((text, index) => (
                  <Typography key={index} sx={{ fontSize: '18px', fontWeight: 'bolder' }}>
                    <li>{text}</li>
                    {!matchDownSM && <br />}
                  </Typography>
                ))}

                {!matchDownSM && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </Box>
            </div>
          )
        )}
      </OwlCarousel>
    </div>
  );
};

export default OwlCarouselComponent;
