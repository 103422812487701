/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { subscribe } from '../actions/stripe';
import { subscribeUser } from '../actions/userActions';

const api_url = process.env.REACT_APP_API_URL;

const StripCheckout = () => (
  <form action={api_url + `/create-checkout-session`} method="POST">
    {/* Add a hidden field with the lookup_key of your Price */}
    <input type="hidden" name="lookup_key" value={'premium_monthly'} />
    <Button variant={'contained'} className="buy-btn" type="submit">
      Skip the free trial
    </Button>
  </form>
);

const SuccessDisplay = ({ sessionId }) => {
  return (
    <div className="product Box-root">
      <div className="description Box-root">
        <h3>Subscription to starter plan successful!</h3>
      </div>
    </div>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

function App(props) {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  async function useEffectHandler() {
    try {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);
      const queryResult = query.get('success');
      if (queryResult) {
        console.log('query', query);
        console.log('queryResult', queryResult);

        let data = {
          userID: props.auth.user.id,
          subscriptionID: query.get('session_id'),
          type: 'premium'
        };
        await subscribeUser(data);
        setSuccess(true);
        setSessionId(query.get('session_id'));
      }

      if (query.get('canceled')) {
        setSuccess(false);
        setMessage('Order canceled');
      }
    } catch (error) {
      // Handle error
      console.log(error);
    }
  }

  useEffect(() => {
    useEffectHandler();
  }, [sessionId]);

  if (!success && message === '') {
    return <ProductDisplay />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(StripCheckout);
