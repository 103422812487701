import React, { useState } from 'react';
import { Typography, Button } from '@mui/material';

const ReadMore = ({ text, cutoff }) => {
  const [showFullText, setShowFullText] = useState(false);

  const handleToggleText = () => {
    setShowFullText((prevShowFullText) => !prevShowFullText);
  };

  return (
    <Typography>
      {showFullText ? text : text?.slice(0, cutoff)}
      {text?.length > cutoff && <Button onClick={handleToggleText}>{showFullText ? 'Read Less' : 'Read More'}</Button>}
    </Typography>
  );
};

export default ReadMore;
