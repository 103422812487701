/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogContent, DialogTitle, Paper } from '@mui/material';

const useStyles = makeStyles(() => ({
  image: {
    cursor: 'zoom-in',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}));

const LightboxGallery = ({ image, table }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(image);
  const [thumbnailSrc, setThumbnailSrc] = useState(image); // Initial image is the same as the main image

  useEffect(() => {
    setImgSrc(image);
    setThumbnailSrc(image);
  }, [image]);

  const handleImageClick = () => {
    setOpen(true);
  };

  const handleError = (originalImageUrl) => {
    const originalImageUrlArr = originalImageUrl.split('/');
    const fallbackImage = 'https://trademarks-image-set.s3.us-east-2.amazonaws.com/' + originalImageUrlArr[originalImageUrlArr.length - 1];

    setImgSrc(fallbackImage);
    setThumbnailSrc(fallbackImage);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          aspectRatio: '1 / 1',
          maxWidth: 90,
          maxHeight: 90,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}

        <Paper
          style={{
            width: '100%',
            height: '100%',
            aspectRatio: '1 / 1', // Set the aspect ratio to 1:1 for a square box, adjust as needed
            backgroundImage: table
              ? `url("${'https://trademarks-image-set.s3.us-east-2.amazonaws.com/' + image.split('/')[image.split('/').length - 1]}")`
              : `url("${image}")`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            maxWidth: 90,
            maxHeight: 90
          }}
          className={classes.image}
          onClick={handleImageClick}
        />
        {/* <img
          style={{display:'none'}}
          src={thumbnailSrc}
          alt=""
          className={classes.image}
          onClick={handleImageClick}
          onKeyDown={(e) => { if (e.key === 'Enter') handleImageClick(); }}
          onError={()=>handleError(image)}
        /> */}
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <img src={imgSrc} onError={() => handleError(image)} alt="Image not found" className={classes.image} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LightboxGallery;
