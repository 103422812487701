import { GET_ERRORS, RESET_ERRORS } from '../../actions/types';

const initialState = '';

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload;
    case RESET_ERRORS:
      return initialState; // Reset the state to its initial value
    default:
      return state;
  }
}

export const resetErrors = () => {
  return {
    type: RESET_ERRORS
  };
};
