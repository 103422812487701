// project import

import home from './home';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [home]
};

export default menuItems;
