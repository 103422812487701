import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

const CookieBanner = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    localStorage.setItem('cookie', 'true');
    // You can implement the logic to handle the user accepting cookies here.
    // For example, set a cookie or save the user's preference in the local storage.
    handleClose();
  };

  const handleReject = () => {
    localStorage.setItem('cookie', 'false');
    // You can implement the logic to handle the user rejecting cookies here.
    // For example, prevent the use of certain tracking scripts or features.
    handleClose();
  };

  const navigateToTerms = () => {
    window.open('/privacy-policy');
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
        backgroundColor: 'white',
        color: 'text.primary'
      }}
      open={open}
      message="This website uses cookies to ensure you get the best experience on our website."
      action={
        <>
          <Button color="primary" size="small" onClick={handleAccept}>
            I Accept
          </Button>
          <Button color="secondary" size="small" onClick={handleReject}>
            Reject
          </Button>
          <Button color="secondary" size="small" onClick={navigateToTerms}>
            Check terms
          </Button>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default CookieBanner;
