import React from 'react';
import { Card, CardActionArea, CardMedia, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const SelectableCard = ({ imageSrc, onSelect, selected, add, notSelectable }) => {
  return (
    <Card
      sx={{
        maxWidth: 80,
        border: selected ? '2px solid #007bff' : '1px solid grey',
        mb: 2 // Margin bottom for spacing
      }}
      onClick={onSelect}
    >
      <CardActionArea disabled={notSelectable} sx={{ position: 'relative', height: 80, width: 80 }}>
        {add ? (
          <IconButton sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <AddIcon />
          </IconButton>
        ) : (
          <CardMedia
            component="img"
            sx={{
              height: 80,
              width: 80, // Ensure the width here matches the card or desired width
              objectFit: 'contain'
            }}
            image={imageSrc}
            alt="Selectable"
          />
        )}
      </CardActionArea>
    </Card>
  );
};

export default SelectableCard;
