import axiosInstance from '../axiosInstance';

export const requestData = async (userID) => {
  try {
    await axiosInstance.post('/api/request-data', { userID });
    // await axiosInstance.get(`/api/export-data/${userID}`)
  } catch (error) {
    console.error(error);
  }
};

export const requestDeletion = async (userID) => {
  try {
    await axiosInstance.post('/api/request-deletion', { userID });
  } catch (error) {
    console.error(error);
  }
};
