/* eslint-disable */
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info'; // Importing the Info icon
import Tooltip from '@mui/material/Tooltip';

import { Dialog, DialogContent, DialogTitle, Box, Grid } from '@mui/material';

function Info() {
  const [showInfo, setShowInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleInfo = () => {
    setOpen(!showInfo);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h7">Nothing to do</Typography>
        <Tooltip title="Click here for help">
          <IconButton onClick={toggleInfo} color="primary" aria-label="show info">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ paddingBottom: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InfoIcon color="primary" aria-label="show info" sx={{ marginRight: 1 }} />
            <Typography>Nothing to do</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h7">
            Your subscription has been successfully canceled, but you will retain access to the service until the end of your current
            billing cycle. At that time, we will send you an email with options to renew your plan, should you wish to do so. Thank you for
            being a valued member of our community!{' '}
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Info;
