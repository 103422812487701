/* eslint-disable */
import axiosInstance from '../axiosInstance';

export const addImage = async (data) => {
  try {
    const currentDate = new Date();
    const imageRes = await axiosInstance.post('/api/image-add', data.formData);
    const imageURL = imageRes.data.imageURL;
    let searchDataToAdd = data.trademark;
    searchDataToAdd.imageURL = imageURL;
    const stringDate = dateToString(currentDate);
    searchDataToAdd.localDate = stringDate;
    console.log('searchDataToAdd', searchDataToAdd);
    const searchRes = await axiosInstance.post('/api/search-add', searchDataToAdd);
    return searchRes; // Resolve the Promise with searchRes
  } catch (error) {
    throw error; // Reject the Promise with the error
  }
};

export const uploadImage = async (data) => {
  try {
    const imageRes = await axiosInstance.post('/api/upload-image', data.formData);
    const imageURL = imageRes.data.imageURL;

    return imageURL;
  } catch (error) {
    throw error;
  }
};

function dateToString(date) {
  // Array of month names
  var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // Get the day of the month and pad with leading zero if necessary
  var day = date.getDate();
  day = day < 10 ? '0' + day : day;

  // Get the full month name
  var month = months[date.getMonth()];

  // Get the year
  var year = date.getFullYear();

  // Format the date string
  var formattedDate = month + ' ' + day + ', ' + year;

  return formattedDate;
}
