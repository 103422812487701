import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearBuffer() {
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const [message, setMessage] = React.useState('Initializing...');
  const messages = ['Analyzing image...', 'Finding matches...', 'Compiling results...'];

  const [randomMessage, setRandomMessage] = React.useState(0);
  const progressRef = React.useRef(() => {});

  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setMessage('Loading...');
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        if (progress < 30) {
          setRandomMessage(0);
        } else if (progress < 60) {
          setRandomMessage(1);
        } else if (progress < 90) {
          setRandomMessage(2);
        }
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
        setMessage(messages[randomMessage]);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      {message}
      <LinearProgress sx={{ marginTop: 1 }} variant="buffer" value={progress} valueBuffer={buffer} />
    </Box>
  );
}
