import React, { useEffect, useState } from 'react';
import MultiSelect from './chip-multi-select/index';
import { Typography } from '@mui/material';

const InternationalClassesFilterModal = ({ data, onChange, selectedFilters, setFilters, isSubscribed, search }) => {
  const [filterUsClasses, setFilterUsClasses] = useState([]);

  useEffect(() => {
    // Loop over the data array and extract all unique US classes
    const usClassesSet = new Set(data?.flatMap((item) => item?.internationalClasses));

    // Convert the set of unique classes to an array of objects with the "label" property
    const usClassesOptions = Array.from(usClassesSet)?.map((usClass) => ({ label: usClass?.code, description: usClass?.description }));

    // Merge the selectedFilters with usClassesOptions and set isSelected to true for matching filters
    const mergedFilters = usClassesOptions?.map((filter) => ({
      ...filter,
      isSelected: selectedFilters?.some((selectedFilter) => selectedFilter?.label === filter?.label)
    }));
    setFilterUsClasses(mergedFilters);
  }, [data, selectedFilters, search]);

  return (
    <>
      <div
        style={{
          backgroundColor: 'white',
          top: 0,
          padding: 10,
          paddingLeft: 0,
          paddingBottom: 0,
          paddingTop: 0,
          alignItems: 'center',
          marginTop: 5
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>International Classes</Typography>
        <MultiSelect
          isSubscribed={isSubscribed}
          selectedFilters={selectedFilters}
          setFilters={setFilters}
          onFilterChange={onChange}
          listOfFilterProp={filterUsClasses}
        />
      </div>
    </>
  );
};

export default InternationalClassesFilterModal;
