import { useRoutes, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import { useDispatch } from 'react-redux';
import { resetErrors } from 'store/auth/errorReducers';
import { useEffect } from 'react';
import SubscriptionRoutes from './SubscriptionRoutes';
import VerificationRoutes from './VerificationRoutes';
import { getUser } from 'actions/userActions';
import { setCurrentUser } from 'actions/authActions';

function ThemeRoutes({ auth }) {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(resetErrors());
  }, [dispatch, location, auth]);

  useEffect(() => {
    getUser({ ...auth.user, _id: auth.user.id })
      .then((data) => {
        setCurrentUser(data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location]);

  const routes = auth.isAuthenticated
    ? !auth.user.emailVerified
      ? [VerificationRoutes]
      : auth.user.subscription
      ? [MainRoutes]
      : [SubscriptionRoutes]
    : [LoginRoutes];

  return useRoutes(routes);
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps)(ThemeRoutes);
