import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// apex-chart
import 'assets/third-party/apex-chart.css';

// project import
import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import enTranslations from './i18n/en.json';
import frTranslations from './i18n/fr.json';
import { logoutUser, setCurrentUser } from 'actions/authActions';
import setAuthToken from 'utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { Elements } from '@stripe/react-stripe-js';
import Analytics from 'analytics/Analytics';
import { loadStripe } from '@stripe/stripe-js';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    fr: { translation: frTranslations }
  },
  lng: 'en', // Default language (you can change it as needed)
  interpolation: {
    escapeValue: false // React already escapes values, so no need for additional escaping
  }
});

const REACT_APP_STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;
const stripePromise = loadStripe(REACT_APP_STRIPE_SECRET_KEY);
if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = '/login';
  }
}
// console.log = () => {};
console.error = () => {};
console.warn = () => {};
console.info = () => {};

ReactGA.initialize('G-5NY4TXYKY5');
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <Elements stripe={stripePromise}>
      <Analytics />
      <ReduxProvider store={store}>
        <BrowserRouter basename="/">
          <I18nextProvider i18n={i18n}>
            <div style={{ height: '100vh' }}>
              <App />
            </div>
          </I18nextProvider>
        </BrowserRouter>
      </ReduxProvider>
    </Elements>
  </StrictMode>
);

reportWebVitals();
