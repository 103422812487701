import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSessionTimeout: false,
  showProceedPopup: false,
  proceedMessage: ''
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionTimeout: (state, action) => {
      state.isSessionTimeout = action.payload;
    },
    setShowProceedPopup: (state, action) => {
      state.showProceedPopup = action.payload.show;
      state.proceedMessage = action.payload.message || '';
    }
  }
});

export const { setSessionTimeout, setShowProceedPopup } = sessionSlice.actions;
export default sessionSlice.reducer;
