/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { makeAspectCrop, centerCrop, Crop, PixelCrop, convertToPixelCrop } from 'react-image-crop';
import { useDebounceEffect } from './useDebounceEffect';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from './canvasPreview';

const Cropper = ({ image, setImage, croppedImageUrl, setCroppedImageUrl }) => {
  const [imgSrc, setImgSrc] = useState(image);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef('');
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(undefined);

  useEffect(() => {
    if (completedCrop) {
      const croppedImage = getCroppedImg(image, completedCrop);
      setCroppedImageUrl(croppedImage);
    }
  }, [completedCrop, image]);

  useEffect(() => {
    setCroppedImageUrl(image);
  }, [image]);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => setImage(reader.result?.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist');
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob');
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      hiddenAnchorRef.current.href = blobUrlRef.current;
      hiddenAnchorRef.current.click();
    });
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      const newCrop = centerAspectCrop(width, height, 16 / 9);
      setCrop(newCrop);
      // Updates the preview
      setCompletedCrop(convertToPixelCrop(newCrop, width, height));
    }
  }

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    const croppedImage = cropImageFunction(image, croppedAreaPixels);
    setImage(croppedImage); // Update the original image state (if needed)
  };

  function getCroppedImg(imageSrc, crop) {
    const canvas = document.createElement('canvas');
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    const image = new Image();
    image.src = imageSrc;
    ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);

    return canvas.toDataURL();
  }
  return (
    <div style={{ width: 'auto', height: 'auto' }}>
      {!!image && (
        <ReactCrop
          style={{ width: 'auto', height: 'auto' }}
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img ref={imgRef} src={image} onLoad={onImageLoad} alt="Crop me" style={{ width: '4', height: 'auto' }} />
          {/* <img ref={imgRef} alt="Crop me" src={image} style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} onLoad={onImageLoad} /> */}
        </ReactCrop>
      )}
    </div>
  );
};

export default Cropper;

const cropImageFunction = (imageSrc, croppedAreaPixels) => {
  // Create a new image element with the original image source
  const img = new Image();
  img.src = imageSrc;

  // Create a canvas element
  const canvas = document.createElement('canvas');

  // Set the canvas size to the cropped area size
  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;

  // Get the 2D context of the canvas
  const ctx = canvas.getContext('2d');

  // Draw the cropped area from the original image onto the canvas
  // The arguments are: image, source x, source y, source width, source height,
  // destination x, destination y, destination width, destination height
  ctx.drawImage(
    img,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  // Convert the canvas to a data URL representing the cropped image
  const croppedImage = canvas.toDataURL();

  return croppedImage;
};
