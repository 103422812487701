/* eslint-disable */
import { Box, Grid, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addTableLogs, exportPDF, exportWord, getSearchByID, updateSearchByID } from '../../actions/searchesActions';
import { getTrademarks } from '../../actions/trademarksActions';
import { getApcByID } from '../../actions/apc';
import EditableLabel from 'components/EditableLabel';
import DragDropTable from 'components/TableReorder';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material';
import NoResults from '../../assets/images/icons/search.png';
import TextInput from 'components/TextInput';
import MainCard from 'components/MainCard';
import LightboxGallery from 'components/LightboxGallery';
import Iframe from 'react-iframe';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import UsClassesFilterModal from 'components/UsClassesFilterModal';
import InternationalClassesFilterModal from 'components/InternationalClassesFilterModal';
import StatusFilterModal from 'components/StatusFilterModal';
import { hasIn } from 'lodash';
import LinearBuffer from 'components/LinearBuffer';
import { ToastContainer, toast } from 'react-toastify';
import DesignSearchListFilterModal from 'components/DesignSearchListFilterModal';
import AuthFooter from 'components/cards/AuthFooter';

import Subscriptions from 'pages/subscribe/index';
import PageExplanation from 'components/page-explanation/index';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormatIndentIcreaseIcon from '@mui/icons-material/FormatIndentIncrease';

function Results({ auth }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchData, setSearchData] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [labelText, setLabelText] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(true);
  const [trademarkToAdd, setTrademarkToAdd] = useState(null);
  const [regID, setRegID] = React.useState(null);
  const [usFilters, setFilters] = React.useState([]);
  const [internationalFilters, setInternationalFilters] = React.useState([]);
  const [statusFilters, setStatusFilters] = React.useState([]);
  const [designSearchFilters, setDesignSearchFilters] = React.useState([]);
  const user = auth.user;
  const isSubscribed = !!auth.user?.subscription;
  const [iframe, setIframe] = React.useState(false);
  const [openSubscribeModal, setOpenSubscribeModal] = React.useState(false);
  const [searchNotFound, setSearchNotFound] = React.useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchMoreData = async () => {
    console.log({ isSubscribed });
    if (!isSubscribed) {
      setOpenSubscribeModal(true);
    } else {
      try {
        setLoadingMore(true);
        console.log('searchData?.extra?.length ', searchData?.extra?.length);
        if (searchData?.extra?.length === 0) {
          await getMoreDataAtEmptyExtra(searchData, dataTable, originalData);
          return;
        }

        const index = searchData?.pageIndex ? searchData?.pageIndex + 1 : 2;

        const updatedExtra = searchData?.extra || [];
        const updatedTable = [...(searchData?.table || []), ...updatedExtra];
        const updatedSearchDataWithoutExtra = { ...searchData, pageIndex: index, table: updatedTable, extra: [] };
        await updateSearchByID(id, { ...updatedSearchDataWithoutExtra });
        setDataTable((prevDataTable) => [...prevDataTable, ...updatedExtra]);
        setOriginalData((prevOriginalData) => [...prevOriginalData, ...updatedExtra]);

        filterDataOnLoadMore([...originalData, ...updatedExtra], usFilters, internationalFilters, statusFilters, designSearchFilters);

        const searchImageProperties = { id, imageURL: searchData?.imageURL, pageIndex: index };

        const newData = await getTrademarks(searchImageProperties).then((trademarks) => trademarks?.data?.extra);
        const updatedSearchData = { ...searchData, pageIndex: index, table: updatedTable, extra: newData };

        setSearchData(updatedSearchData);

        await updateSearchByID(id, { ...updatedSearchData, table: updatedTable, extra: newData });
      } catch (e) {
        console.log({ e });
      } finally {
        setLoadingMore(false);
        setLoading(false);
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setLoadingMore(true);

    try {
      const { data } = await getSearchByID(id);
      if (!data) return;

      if (data.notFound) {
        setSearchNotFound(true);
        return;
      }

      data.table?.forEach((element) => {
        if (!element.hidden) {
          element.hidden = false;
        }
      });

      setSearchData(data);

      const { usFilters, internationalFilters, statusFilters, designSearchFilters, table, label, date, imageURL } = data;
      usFilters && setFilters(usFilters);
      internationalFilters && setInternationalFilters(internationalFilters);
      statusFilters && setStatusFilters(statusFilters);
      designSearchFilters && setDesignSearchFilters(designSearchFilters);
      console.log('table?.length', table?.length);
      if (table?.length > 0) {
        setDataTable(table);
        setOriginalData(table);
        setLabelText(label?.length ? label : formatDateToCustomFormat(date));
        console.log('data?.extra?.length', data?.extra?.length);
        console.log('data?.table?.length !== 0', data?.table?.length !== 0);
        if (data?.extra?.length === 0 && data?.table?.length !== 0) {
          console.log('getMoreDataAtEmptyExtra');
          await getMoreDataAtEmptyExtra(data, table, table);
        }
        console.log({ data });
      } else {
        const searchIdImage = { id, imageURL };
        const trademarks = await getTrademarks(searchIdImage);
        const sortedTable = trademarks?.data?.table?.sort((a, b) => b.score - a.score);

        await updateSearchByID(id, { table: sortedTable, extra: trademarks?.data?.extra });
        setSearchData({ id, table: sortedTable, extra: trademarks?.data?.extra, pageIndex: 1, imageURL });
        if (trademarks.data?.extra?.length === 0 && sortedTable?.length !== 0) {
          getMoreDataAtEmptyExtra(
            { id, table: sortedTable, extra: trademarks?.data?.extra, pageIndex: 1, imageURL },
            sortedTable,
            sortedTable
          );
        }
        setDataTable(sortedTable);
        setOriginalData(sortedTable);
        console.log({ sortedTable });
        setLabelText(label?.length ? label : formatDateToCustomFormat(date));
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const getMoreDataAtEmptyExtra = async (searchData, dataTable, originalData) => {
    setLoadingMore(true);
    try {
      const index = searchData?.pageIndex || 1;
      const searchImageProperties = { id, imageURL: searchData?.imageURL, pageIndex: index + 1 };
      console.log({ index });
      console.log({ searchImageProperties });
      await updateSearchByID(id, {
        pageIndex: index,
        extra: []
      });

      const trademarks = await getTrademarks(searchImageProperties);
      console.log({ trademarks });
      const newData = trademarks?.data?.extra || [];
      console.log({ newData });
      if (newData.length > 0) {
        setDataTable([...dataTable, ...searchData?.extra]);
        setOriginalData([...originalData, ...searchData?.extra]);

        filterDataOnLoadMore([...originalData, ...searchData?.extra], usFilters, internationalFilters, statusFilters, designSearchFilters);

        setSearchData(() => ({
          ...searchData,
          pageIndex: index + 1,
          table: [...searchData.table, ...searchData.extra],
          extra: newData
        }));

        await updateSearchByID(id, {
          pageIndex: index + 1,
          table: [...searchData?.table, ...searchData?.extra],
          extra: newData
        });
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoadingMore(false);
      setLoading(false);
    }
  };

  const compareArrays = (originalArray, modifiedArray, type, id) => {
    const originalMap = new Map();
    const modifiedMap = new Map();
    const changes = [];

    originalArray.forEach((item, index) => originalMap.set(item.id, { index, hidden: item.hidden }));
    modifiedArray.forEach((item, index) => modifiedMap.set(item.id, { index, hidden: item.hidden }));

    if (type === actionType.ADDED || type === actionType.MOVED) {
      for (let [index, item] of modifiedArray.entries()) {
        const original = originalMap.get(item.id);

        if (!original && !item.hidden && type === actionType.ADDED) {
          return [
            {
              type: actionType.ADDED,
              index,
              value: item['serial-number']
            }
          ];
        } else if (original && type === actionType.MOVED && original.index !== index) {
          return [
            {
              type: actionType.MOVED,
              fromIndex: original.index,
              toIndex: index,
              movement: index - original.index,
              value: item['serial-number']
            }
          ];
        }
      }
    }

    if (type === actionType.HIDE || type === actionType.UNHIDE) {
      const item = modifiedArray.find((item) => item.id === id);
      if (item) {
        return [
          {
            type: type,
            index: modifiedMap.get(id).index,
            value: item['serial-number']
          }
        ];
      }
    }

    if (type === actionType.REMOVED) {
      for (let [index, item] of originalArray.entries()) {
        if (!modifiedMap.has(item.id) && !item.hidden) {
          return [
            {
              type: actionType.REMOVED,
              index,
              value: item['serial-number']
            }
          ];
        }
      }
    }

    return [];
  };

  const Pdf = () => exportPDF(user.id, id);

  const Word = () => exportWord(user.id, id);

  const save = async (label) => {
    if (!isSubscribed) {
      setOpenSubscribeModal(true);
    } else {
      setLabelText(label);
      await updateSearchByID(id, { label });
    }
  };
  const handleOpenSubscribeModal = () => {
    setOpenSubscribeModal(true);
  };

  const onChangeTable = async (dataTable, type, itemId) => {
    if (!isSubscribed) {
      setOpenSubscribeModal(true);
    } else {
      const changes = compareArrays(originalData, dataTable, type, itemId);
      setDataTable(dataTable);

      try {
        await Promise.all([addTableLogs(id, changes), updateSearchByID(id, { table: dataTable })]);
      } catch (error) {}
    }
  };

  const onEditClick = () => {
    if (!isSubscribed) {
      setOpenSubscribeModal(true);
    } else {
      navigate(`/report/${id}`, { state: { id: 7, color: 'green' } });
    }
  };

  const formatDateToCustomFormat = (mongoDate) => {
    const date = new Date(mongoDate);
    const timestamp = date.getTime();
    return timestamp;
  };

  const addTrademark = async () => {
    if (!isSubscribed) {
      setOpenSubscribeModal(true);
    } else {
      let prefixes = [''];
      if (regID.length == 7) {
        prefixes = ['rn', 'ir'];
      }

      let data = null;
      for (const prefix of prefixes) {
        const testRegID = prefix + regID;
        data = await getApcByID(testRegID);

        if (data?.data !== undefined || data?.status <= 200) {
          break;
        }
      }

      if (!data || data?.data === undefined || data?.status > 200) {
        toast.error('No results were found', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
      } else {
        setTrademarkToAdd(data);
      }
    }
  };

  const handleClose = () => {
    setIframe(false);
    setOpenSubscribeModal(false);
  };

  const filterData = (selectedUsFilters, selectedInternationalFilters, selectedStatusFilters, selectedDesignSearchFilters) => {
    if (
      selectedUsFilters?.length === 0 &&
      selectedInternationalFilters?.length === 0 &&
      selectedStatusFilters?.length === 0 &&
      selectedDesignSearchFilters?.length === 0
    ) {
      const unfilteredData = dataTable?.map((item) => ({ ...item, isFiltered: false }));
      setDataTable(unfilteredData);
      setOriginalData(unfilteredData);
      setFilters([]);
      setInternationalFilters([]);
      setStatusFilters([]);
      setDesignSearchFilters([]);
      return;
    }

    const filteredData = dataTable?.map((item) => {
      const hasUsMatchingFilter = selectedUsFilters.some((filter) => item.usClasses?.some((classObj) => classObj.code === filter.label));
      const hasInternationalMatchingFilter = selectedInternationalFilters.some((filter) =>
        item.internationalClasses?.some((classObj) => classObj.code === filter.label)
      );
      const hasStatusMatchingFilter = selectedStatusFilters.some((filter) => item.status?.status === filter.label);
      const hasDesignMatchingFilter = selectedDesignSearchFilters.some((filter) =>
        item.designSearchList?.some((classObj) => classObj.code === filter.label)
      );

      const isFiltered = hasUsMatchingFilter || hasInternationalMatchingFilter || hasStatusMatchingFilter || hasDesignMatchingFilter;

      return { ...item, isFiltered: !isFiltered };
    });

    setDataTable(filteredData);
    setOriginalData(filteredData);
    setFilters(selectedUsFilters);
    setInternationalFilters(selectedInternationalFilters);
    setStatusFilters(selectedStatusFilters);
    setDesignSearchFilters(selectedDesignSearchFilters);
    saveFilters(selectedUsFilters, selectedInternationalFilters, filteredData, selectedStatusFilters, selectedDesignSearchFilters);
  };

  const saveFilters = async (usFilters, internationalFilters, filteredData, statusFilters, designSearchFilters) => {
    if (!isSubscribed) {
      setOpenSubscribeModal(true);
    } else {
      await updateSearchByID(id, {
        usFilters,
        internationalFilters,
        table: filteredData,
        statusFilters,
        designSearchFilters
      });
    }
  };

  const filterDataOnLoadMore = (
    dataTable,
    selectedUsFilters,
    selectedInternationalFilters,
    selectedStatusFilters,
    selectedDesignSearchFilters
  ) => {
    if (
      selectedUsFilters?.length === 0 &&
      selectedInternationalFilters?.length === 0 &&
      selectedStatusFilters?.length === 0 &&
      selectedDesignSearchFilters?.length === 0
    ) {
      const resetData = dataTable.map((item) => ({ ...item, isFiltered: false }));
      performStateUpdates(resetData, [], [], [], []);
      return;
    }

    const filteredData = dataTable.map((item) => {
      const isFiltered = matchesAnyFilter(
        item,
        selectedUsFilters,
        selectedInternationalFilters,
        selectedStatusFilters,
        selectedDesignSearchFilters
      );

      return { ...item, isFiltered };
    });

    performStateUpdates(filteredData, selectedUsFilters, selectedInternationalFilters, selectedStatusFilters, selectedDesignSearchFilters);
  };

  const matchesAnyFilter = (item, usFilters, internationalFilters, statusFilters, designSearchFilters) => {
    const hasUsMatchingFilter = usFilters.some((filter) => item.usClasses?.some((classObj) => classObj.code === filter.label));
    const hasInternationalMatchingFilter = internationalFilters.some((filter) =>
      item.internationalClasses?.some((classObj) => classObj.code === filter.label)
    );
    const hasStatusMatchingFilter = statusFilters.some((filter) => item.status?.status === filter.label);
    const hasDesignMatchingFilter = designSearchFilters.some((filter) =>
      item.designSearchList?.some((classObj) => classObj.code === filter.label)
    );

    return hasUsMatchingFilter || hasInternationalMatchingFilter || hasStatusMatchingFilter || hasDesignMatchingFilter;
  };

  const performStateUpdates = (filteredData, usFilters, internationalFilters, statusFilters, designSearchFilters) => {
    setDataTable(filteredData);
    setOriginalData(filteredData);
    setFilters(usFilters);
    setInternationalFilters(internationalFilters);
    setStatusFilters(statusFilters);
    setDesignSearchFilters(designSearchFilters);
    saveFilters(usFilters, internationalFilters, filteredData, designSearchFilters);
  };

  return (
    <>
      {searchNotFound ? (
        <Grid container xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid xs={4} md={3}>
            <Typography variant="h5" sx={{ textAlign: 'center' }} className={classes.link}>
              Search Not Found
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={NoResults} style={{ maxWidth: '200px' }} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <MainCard>
          <ToastContainer />
          <Dialog
            sx={{
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  width: '100%',
                  maxWidth: '110vh'
                }
              }
            }}
            open={iframe}
            onClose={handleClose}
          >
            <DialogTitle>{`https://tsdr.uspto.gov/#caseNumber=${iframe}&caseType=DEFAULT&searchType=statusSearch`}</DialogTitle>
            <DialogContent sx={{ height: '90vh' }}>
              <Iframe
                width="100%"
                height="100%"
                url={`https://tsdr.uspto.gov/#caseNumber=${iframe}&caseType=DEFAULT&searchType=statusSearch`}
                style={{ width: '100%', height: '100%', backgroundColor: 'red' }}
                frameBorder="0"
              />
            </DialogContent>
          </Dialog>
          <Dialog
            sx={{
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  width: '100%',
                  maxWidth: '60vh',
                  padding: 0
                }
              }
            }}
            open={openSubscribeModal}
            onClose={handleClose}
          >
            {/* TODO: Anthony check <DialogContent sx={{ width: '250v', height: '90vh' }}> , to make increase width */}
            {/* <DialogTitle>{`You need to subscribe`}</DialogTitle> */}
            <DialogContent sx={{ width: '100%', padding: 0, margin: 0 }}>
              <Subscriptions popup handleClose={handleClose} />
            </DialogContent>
          </Dialog>

          <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'white' }} open={loading}>
            {/* <CircularProgress color="primary" sx={{ mr: 2 }} />
          Analyzing image... */}

            <Box sx={{ width: '30%' }}>
              <LinearBuffer />
            </Box>
          </Backdrop>
          {!loading && (
            <>
              <Grid container gap={2} sx={{ display: 'flex' }}>
                <Grid item xs={12} md={5}>
                  <PageExplanation explainPage={explainPage} />
                  <Box sx={{ display: 'flex', height: 'auto', alignItems: 'center' }}>
                    <EditableLabel labelText={labelText} onChange={save} />
                  </Box>

                  <Grid sx={{ mt: 2 }} container justifyContent={'center'}>
                    <Grid xs={7} md={6} item display={'flex'} alignItems={'center'}>
                      <TextInput placeholder={'Registration or Serial Number'} value={regID} onChange={setRegID} />
                    </Grid>
                    <Grid xs={5} md={6} item display={'flex'} alignItems={'center'}>
                      <Button title="" sx={{ height: '100%', height: '40px' }} variant={'outlined'} onClick={addTrademark}>
                        + Add to list
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} sm={9} item md={3} sx={{ mb: 1 }}>
                  <LightboxGallery image={searchData?.imageURL} />
                </Grid>
                <Grid xs={4} sm={2} item md={3}>
                  <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end', width: '100%', marginLeft: 10 }}>
                    {' '}
                    <Box xs={12} sm={12} item md={3}>
                      <Button sx={{ marginLeft: -1 }} onClick={onEditClick}>
                        {!searchData?.reportID ? 'Edit Report' : 'Edit Report'}
                      </Button>
                      <br />

                      <Grid container sx={{ display: 'flex' }} color="primary.main">
                        <a sx={{ width: '100%' }} style={{ cursor: 'pointer' }} color="primary.main" onClick={Pdf}>
                          {'PDF'}
                        </a>
                        <a style={{ marginLeft: 5, marginRight: 5 }}>|</a>
                        <a onClick={Word} style={{ cursor: 'pointer' }}>
                          {'Word'}
                        </a>
                      </Grid>

                      <br />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mt: 2 }}>
                <UsClassesFilterModal
                  selectedFilters={usFilters}
                  setFilters={setFilters}
                  onChange={(filters) => {
                    filterData(filters, internationalFilters, statusFilters, designSearchFilters);
                  }}
                  isSubscribed={isSubscribed}
                  data={dataTable}
                />
                <InternationalClassesFilterModal
                  selectedFilters={internationalFilters}
                  setFilters={setInternationalFilters}
                  onChange={(filters) => {
                    filterData(usFilters, filters, statusFilters, designSearchFilters);
                  }}
                  isSubscribed={isSubscribed}
                  data={dataTable}
                />
                <DesignSearchListFilterModal
                  selectedFilters={designSearchFilters}
                  setFilters={setDesignSearchFilters}
                  onChange={(filters) => {
                    filterData(usFilters, internationalFilters, statusFilters, filters);
                  }}
                  isSubscribed={isSubscribed}
                  data={dataTable}
                />
                <StatusFilterModal
                  selectedFilters={statusFilters}
                  setFilters={setStatusFilters}
                  isSubscribed={isSubscribed}
                  onChange={(filters) => {
                    filterData(usFilters, internationalFilters, filters, designSearchFilters);
                  }}
                  data={dataTable}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <DragDropTable
                  onChange={onChangeTable}
                  originalData={dataTable}
                  columns={columns}
                  trademarkToAdd={trademarkToAdd}
                  setIframe={setIframe}
                  iframe={iframe}
                  disabled={!isSubscribed}
                  handleOpenSubscribeModal={handleOpenSubscribeModal}
                />
              </Box>
            </>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 2 }}
              onClick={() => {
                fetchMoreData();
              }}
              disabled={loadingMore}
            >
              {loadingMore ? 'Loading...' : 'Load More'}
            </Button>
          </Box>
        </MainCard>
      )}
      <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
        <AuthFooter />
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Results);

const columns = [
  { field: 'image-url', header: 'Images' },
  { field: 'id', header: 'Serial No.' },
  { field: 'status', header: 'Status' },
  { field: 'usClasses', header: 'US' },
  { field: 'internationalClasses', header: 'Int.' },
  { field: 'designSearchList', header: 'Design Codes' },
  { field: 'case-file-header.registration-date', header: 'Reg. Date' },
  { field: 'case-file-owners.case-file-owner[0].party-name', header: 'Registrant' },
  { field: 'case-file-statements.case-file-statement[0].text', header: 'Info' }
];

const useStyles = styled((theme) => ({
  container: {
    display: 'flex',
    margin: theme.spacing(2, 0),
    mb: 5
  },
  imageContainer: {
    flex: '0 0 120px',
    marginRight: theme.spacing(2),
    width: '50px',
    height: '50px'
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  contentContainer: {
    flexGrow: 1
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  reportLinks: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  reportLink: {
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  },
  trashIcon: {
    cursor: 'pointer'
  }
}));

const explainPage = {
  title: 'Your search results are shown in the table below. You can now:',
  footer: (
    <Typography>
      If you leave this page, you can come back to it later from the{' '}
      <span style={{ color: 'blue', textDecoration: 'underline' }}>History</span> tab on the left (click{' '}
      <span style={{ verticalAlign: 'middle' }}>
        <FormatIndentIcreaseIcon sx={{ marginLeft: '5px', marginRight: '5px' }} />
      </span>{' '}
      on the top left if you don't see the tab).
    </Typography>
  ),
  li: [
    <>
      {' '}
      <strong>Narrow your search</strong> by class, code, and status. Trademarks are shown if they satisfy one of the selected statuses{' '}
      <span style={{ textDecoration: 'underline' }}>and</span> at least one of the selected options across classes and codes.
    </>,
    <>
      {' '}
      <strong>Modify the list</strong> by moving{' '}
      <span style={{ verticalAlign: 'middle' }}>
        <OpenWithIcon sx={{ marginLeft: '5px', marginRight: '5px' }} />
      </span>{' '}
      or hiding{' '}
      <span style={{ verticalAlign: 'middle' }}>
        {' '}
        <VisibilityOffIcon sx={{ marginLeft: '5px', marginRight: '5px' }} />{' '}
      </span>
      elements. You can also add to the list (<span style={{ color: 'blue', textDecoration: 'underline' }}>+ Add To List</span>) using a
      trademark's registration or serial number.
    </>,
    <>
      <strong>Edit the knockout search report </strong>using the top right button (
      <span style={{ color: 'blue', textDecoration: 'underline' }}>Edit Report</span>). Edits here only affect this report. To edit the
      default template for all future reports, go to the <span style={{ color: 'blue', textDecoration: 'underline' }}>Templates</span> tab
      on the left (click{' '}
      <span style={{ verticalAlign: 'middle' }}>
        <FormatIndentIcreaseIcon sx={{ marginLeft: '5px', marginRight: '5px' }} />
      </span>{' '}
      on the top left if you don't see the tab).
    </>,
    <>
      {' '}
      <strong>Get your report in</strong> <span style={{ color: 'blue', textDecoration: 'underline' }}>PDF</span> or{' '}
      <span style={{ color: 'blue', textDecoration: 'underline' }}>Word</span> form when you're done (top right buttons).
    </>,
    <>
      <strong>Archive</strong> unconcerning results by clicking [archiving logo]. You can always unarchive any logo.
    </>
  ]
};

const actionType = {
  MOVED: 'moved',
  REMOVED: 'removed',
  ADDED: 'added',
  UNHIDE: 'unhide',
  HIDE: 'hide'
};
