import axiosInstance from '../axiosInstance';

export const getHistory = async (userID) => {
  try {
    const response = await axiosInstance.post('/api/user-searches', userID);
    const localDateResponse = response.data.map((historyEntry) => {
      return { ...historyEntry, date: formatLocalTime(historyEntry.date) };
    });
    return { data: localDateResponse };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getSearchByID = async (_id) => {
  try {
    const response = await axiosInstance.post('/api/search-data', { _id: _id });
    return response;
  } catch (error) {
    return error;
  }
};

export const addTableLogs = async (searchID, logs) => {
  try {
    const response = await axiosInstance.post('/api/add-table-logs', {
      searchID: searchID,
      logs: logs
    });
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateSearchByID = async (_id, data) => {
  try {
    const response = await axiosInstance.post('/api/search-update', { _id: _id, data });

    // console.log({ response });
    return response;
  } catch (error) {
    console.log({ error });

    return error;
  }
};

export const deleteSearchByID = async (_id) => {
  try {
    const response = await axiosInstance.post('/api/search-delete', { _id: _id });
    // console.log({ response });
    return response;
  } catch (error) {
    console.log({ error });
    return error;
  }
};

export const deleteWatchSearchByID = async (_id) => {
  try {
    const response = await axiosInstance.delete(`/api/watch/${_id}`);
    return response;
  } catch (error) {
    console.log({ error });
    return error;
  }
};

export const exportPDF = (userID, searchID) => {
  const token = localStorage.getItem('jwtToken');
  const url = `${process.env.REACT_APP_API_URL}/export-pdf/${userID}/${searchID}?token=${token}`;
  window.open(url, '_blank'); // This opens the URL in a new tab
};

export const exportWatchPDF = (userID, watchID) => {
  const token = localStorage.getItem('jwtToken');
  const url = `${process.env.REACT_APP_API_URL}/export-watch-pdf/${userID}/${watchID}?token=${token}`;
  window.open(url, '_blank'); // This opens the URL in a new tab
};

export const exportWatchWord = (userID, watchID) => {
  const token = localStorage.getItem('jwtToken');
  const url = `${process.env.REACT_APP_API_URL}/export-watch-word/${userID}/${watchID}?token=${token}`;
  window.open(url, '_blank'); // This opens the URL in a new tab
};

export const exportWord = (userID, searchID) => {
  const token = localStorage.getItem('jwtToken');
  const url = `${process.env.REACT_APP_API_URL}/export-word/${userID}/${searchID}?token=${token}`;
  window.open(url, '_blank'); // This opens the URL in a new tab
};

function formatLocalTime(utcTime) {
  var date = new Date(utcTime);

  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, '0');
  var day = date.getDate().toString().padStart(2, '0');
  var hours = date.getHours().toString().padStart(2, '0');
  var minutes = date.getMinutes().toString().padStart(2, '0');
  var seconds = date.getSeconds().toString().padStart(2, '0');
  var milliseconds = date.getMilliseconds().toString().padStart(3, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
}
