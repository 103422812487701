import { useMediaQuery, Container, Typography, Stack, Link } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = () => {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const year = new Date().getFullYear(); // This gets the current year

  return (
    <Container maxWidth="xl">
      <Stack
        direction={matchDownSM ? 'column' : 'row'}
        justifyContent={'center'}
        spacing={2}
        textAlign={matchDownSM ? 'center' : 'inherit'}
      >
        <Typography variant="subtitle2" color="secondary" component="span">
          &copy; Confusability {year}&nbsp;
        </Typography>

        {/* Links to Terms of Service and Privacy Policy */}
        <Stack direction="row" sx={{ display: 'flex', justifyContent: 'center' }} spacing={2}>
          <Link href="/contact" color="inherit" variant="body2">
            Contact Us
          </Link>
          <Link href="/terms-of-service" color="inherit" variant="body2">
            Terms of Service
          </Link>
          <Link href="/privacy-policy" color="inherit" variant="body2">
            Privacy Policy
          </Link>
        </Stack>
      </Stack>
    </Container>
  );
};

export default AuthFooter;
