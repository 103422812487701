/* eslint-disable */
import React, { useEffect, useState } from 'react';
import MultiSelect from './chip-multi-select/index';
import { Typography } from '@mui/material';

const DesignSearchListFilterModal = ({ data, onChange, selectedFilters, setFilters, isSubscribed, search }) => {
  const [filterdesignSearchList, setFilterdesignSearchList] = useState([]);

  useEffect(() => {
    // Loop over the data array and extract all unique US classes
    const designSearchListSet = new Set(data?.flatMap((item) => item?.designSearchList));

    // Convert the set of unique classes to an array of objects with the "label" property
    const designSearchListOptions = Array.from(designSearchListSet)?.map((designSearch) => ({
      label: designSearch?.code,
      description: designSearch?.descriptions
    }));

    // Merge the selectedFilters with designSearchListOptions and set isSelected to true for matching filters
    const mergedFilters = designSearchListOptions?.map((filter) => ({
      ...filter,
      isSelected: selectedFilters?.some((selectedFilter) => selectedFilter?.label === filter?.label)
    }));
    setFilterdesignSearchList(mergedFilters);
  }, [data, selectedFilters, search]);

  return (
    <>
      <div
        style={{
          backgroundColor: 'white',
          top: 0,
          padding: 10,
          paddingLeft: 0,
          paddingBottom: 0,
          paddingTop: 0,
          alignItems: 'center',
          marginTop: 5
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>Design Search Codes</Typography>
        <MultiSelect
          isSubscribed={isSubscribed}
          designSearch
          selectedFilters={selectedFilters}
          setFilters={setFilters}
          onFilterChange={onChange}
          listOfFilterProp={filterdesignSearchList}
        />
      </div>
    </>
  );
};

export default DesignSearchListFilterModal;
