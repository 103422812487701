/* eslint-disable */
import axiosInstance from 'axiosInstance';

const createWatch = async (userID, searchID, imageURL, frequency) => {
  try {
    const currentDate = new Date();
    const stringDate = dateToString(currentDate);
    const localDate = stringDate;

    const response = await axiosInstance.post('/api/watch', {
      userID,
      searchID,
      imageURL,
      frequency,
      localDate
    });

    return response.data;
  } catch (err) {
    return null;
  }
};

const getWatchByID = async (watchID) => {
  try {
    return await axiosInstance.get(`/api/watch/${watchID}`);
  } catch (err) {
    return null;
  }
};

const subscribe = async ({ userID, watchID }) => {
  try {
    const response = await axiosInstance.post('/api/watch/subscribe', {
      userID,
      watchID
    });
    return response.data;
  } catch (error) {}
};

const createCronjob = async (userID, watchID, email, frequency) => {
  try {
    const response = await axiosInstance.post('/api/create-cronjob', {
      userID,
      watchID,
      email,
      frequency
    });

    return response.data;
  } catch (err) {
    return null;
  }
};

const updateWatch = async (watchID, update) => {
  try {
    console.log(watchID, update);
    const response = await axiosInstance.put(`/api/watch/${watchID}`, {
      update
    });

    return response.data;
  } catch (err) {
    return null;
  }
};

const updateWatchFrequency = async (userID, frequency) => {
  console.log(userID, frequency);
  try {
    const response = await axiosInstance.post(`/api/watch-update-frequency/${userID}`, {
      frequency
    });

    return response.data;
  } catch (err) {
    return null;
  }
};

const deleteWatch = async (watchID) => {
  try {
    const response = await axiosInstance.delete(`/api/watch/${watchID}`);

    return response.data;
  } catch (err) {
    return null;
  }
};

const unwatchSearch = async (searchID) => {
  try {
    const response = await axiosInstance.post(`/api/unwatch/${searchID}`);

    return response.data;
  } catch (err) {
    return null;
  }
};

const fetchWatchedSearches = async (userID) => {
  try {
    const response = await axiosInstance.get(`/api/searches/watched/${userID}`);

    return response.data ? response.data : [];
  } catch (error) {
    // Handle the error accordingly
    return null;
  }
};

const fetchWatchedSearchesLatestData = async (userID) => {
  try {
    const response = await axiosInstance.post(`/api/execute-search-watch`, {
      userID
    });
    console.log({ response });
    return response?.data?.watches ? response.data.watches : [];
  } catch (error) {
    // Handle the error accordingly
    return null;
  }
};

function dateToString(date) {
  // Array of month names
  var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // Get the day of the month and pad with leading zero if necessary
  var day = date.getDate();
  day = day < 10 ? '0' + day : day;

  // Get the full month name
  var month = months[date.getMonth()];

  // Get the year
  var year = date.getFullYear();

  // Format the date string
  var formattedDate = month + ' ' + day + ', ' + year;

  return formattedDate;
}

export {
  createWatch,
  updateWatch,
  deleteWatch,
  unwatchSearch,
  fetchWatchedSearches,
  subscribe,
  getWatchByID,
  updateWatchFrequency,
  fetchWatchedSearchesLatestData
};
