import React, { useRef, useState } from 'react';
import { Grid, OutlinedInput, Typography } from '@mui/material';

const OTPInput = ({ length = 4, submitOTP, error }) => {
  const inputRefs = useRef([]);
  const [isFull, setIsFull] = useState(false);
  const focusNextInput = (index) => {
    if (index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const focusPrevInput = (index) => {
    if (index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value;

    // Handle pasting directly into the input boxes
    if (value.length > 1) {
      const valuesArray = value.split('').slice(0, length - index);
      valuesArray.forEach((char, i) => {
        inputRefs.current[index + i].value = char;
      });
      handleResendOTP(getOTPValue());
    } else {
      inputRefs.current[index].value = value;
    }

    // Move focus to the next input box if available
    if (value.length === 1 && index < length - 1) {
      focusNextInput(index);
    }
    if (value.length === 0 && index > 0) {
      focusPrevInput(index);
    }
    if (value.length === 1 && index === length - 1) {
      handleResendOTP(getOTPValue());
    }
  };

  const handleInputKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && !inputRefs.current[index].value) {
      focusPrevInput(index);
      setIsFull(false);
    }
  };

  const getOTPValue = () => {
    return inputRefs.current.map((ref) => ref.value).join('');
  };

  const handleResendOTP = (value) => {
    submitOTP(value);
    setIsFull(true);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {[...Array(length)].map((_, index) => (
          <Grid item key={index}>
            <OutlinedInput
              inputRef={(ref) => (inputRefs.current[index] = ref)}
              type="text"
              inputProps={{
                maxLength: 4,
                style: {
                  textAlign: 'center',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  letterSpacing: '12px', // Adjust as needed for spacing between characters
                  width: '40px' // Adjust as needed for input width
                }
              }}
              onKeyDown={(event) => handleInputKeyDown(index, event)}
              onChange={(event) => handleInputChange(index, event)}
              autoFocus={index === 0}
              labelWidth={0}
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
      {error && isFull && (
        <div style={{ paddingTop: '15px' }}>
          <Typography variant="p" color="error">
            {error}
          </Typography>
        </div>
      )}
    </>
  );
};

export default OTPInput;
