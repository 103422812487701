import { useEffect } from 'react';
import { createBrowserHistory } from 'history';

const Analytics = () => {
  const history = createBrowserHistory();
  useEffect(() => {
    window.gtag('config', 'G-5NY4TXYKY5', {
      page_path: history.location.pathname
    });
    // ReactGA.initialize('G-5NY4TXYKY5');
    // // Track page views
    // alert(history.location.pathname)
    // ReactGA.set({ page: history.location.pathname });
    // ReactGA.pageview(history.location.pathname);
  }, [history]);

  return null;
};
export default Analytics;
