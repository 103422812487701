import React, { useEffect, useState } from 'react';
import { Typography, TextField, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

const EditableLabel = ({ labelText, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [label, setLabelText] = useState('');
  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    setLabelText(labelText);
  }, [labelText]);

  const handleBlur = () => {
    setIsEditing(false);
    onChange(label);
  };

  const handleChange = (e) => {
    setLabelText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission (if any)
      e.stopPropagation();
      handleBlur();
    }
  };

  return (
    <div className="editable-label" style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
      <Typography
        variant="subtitle1"
        //   onClick={handleDoubleClick}
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', height: '20px', marginTop: 5, marginRight: 5 }}
      >
        Title:
      </Typography>

      {isEditing ? (
        <TextField
          InputProps={{ style: { textAlign: 'center' } }}
          variant="standard"
          value={label}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          fullWidth
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Typography
          variant="subtitle1"
          onClick={handleDoubleClick}
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', height: '20px', marginTop: 5 }}
        >
          {labelText}{' '}
          <IconButton size="small" onClick={handleDoubleClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Typography>
      )}
    </div>
  );
};

export default EditableLabel;
