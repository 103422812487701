import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import logo from '../../../../assets/images/logo.png';
// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    // only available in paid version
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack
        direction="row"
        onClick={() => {
          location.href = '/';
        }}
        spacing={1}
        alignItems="center"
        sx={{ fontSize: '15px', cursor: 'pointer' }}
      >
        <img src={logo} alt="Logo" style={{ width: '20%', height: 'auto' }} />
        Confusability AI
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
