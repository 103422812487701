/* eslint-disable */
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info'; // Importing the Info icon
import Tooltip from '@mui/material/Tooltip';

import { Dialog, DialogContent, DialogTitle, Box, Grid } from '@mui/material';

function PageExplanation({ explainPage }) {
  const [showInfo, setShowInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleInfo = () => {
    setOpen(!showInfo);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>Explain this page to me</Typography>
        <Tooltip title="Click here for help">
          <IconButton onClick={toggleInfo} color="primary" aria-label="show info">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ paddingBottom: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InfoIcon color="primary" aria-label="show info" sx={{ marginRight: 1 }} />
            <Typography>{explainPage?.title}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ol>
            {explainPage?.li?.map((list) => (
              <li>{list}</li>
            ))}
          </ol>
          {explainPage?.footer}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default PageExplanation;
