/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SelectableCard from 'components/selectableCard/index';
import { connect } from 'react-redux';
import DragDropTable from 'components/TableReorder';
import { useTheme } from '@mui/material/styles';
import { createWatch, fetchWatchedSearches, fetchWatchedSearchesLatestData, updateWatch, updateWatchFrequency } from 'actions/watch';
import Iframe from 'react-iframe';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import ModernImageInput from 'components/ImageInput';
import { addImage, uploadImage } from 'actions/imageActions';
import PageExplanation from 'components/page-explanation/index';
import Subscriptions from 'pages/subscribe/index';
import MainCard from 'components/MainCard';
import { ToastContainer, toast } from 'react-toastify';
import { EyeOutlined } from '@ant-design/icons';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import WatchSubscribeCard from 'components/WatchSubscribeCard';
import UsClassesFilterModal from 'components/UsClassesFilterModal';
import InternationalClassesFilterModal from 'components/InternationalClassesFilterModal';
import DesignSearchListFilterModal from 'components/DesignSearchListFilterModal';
import StatusFilterModal from 'components/StatusFilterModal';
import { addTableLogs, exportWatchPDF, exportWatchWord } from 'actions/searchesActions';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import EditableLabel from 'components/EditableLabel';
import axiosInstance from 'axiosInstance';
import { addReport } from 'actions/reportActions';
import ArchiveIcon from '@mui/icons-material/Inventory';
import TextInput from 'components/TextInput';
import { getApcByID } from 'actions/apc';
import { getUser } from 'actions/userActions';

const Watch = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedCard, setSelectedCard] = useState(null);
  const [search, setSearch] = useState(null);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [emailFrequency, setEmailFrequency] = useState('everyWednesday');
  const [category, setCategory] = useState('watchList');
  const [iframe, setIframe] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [image, setImage] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [openWatchingSince, setOpenWatchingSince] = useState(false);
  const [usFilters, setFilters] = useState([]);
  const [internationalFilters, setInternationalFilters] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [designSearchFilters, setDesignSearchFilters] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [labelText, setLabelText] = useState('');
  const [trademarkToAdd, setTrademarkToAdd] = useState(null);
  const [openSubscribeModal, setOpenSubscribeModal] = React.useState(false);
  const [regID, setRegID] = React.useState(null);
  const [loadingNewData, setLoadingNewData] = React.useState(false);

  const compareArrays = (originalArray, modifiedArray, type, id) => {
    const originalMap = new Map();
    const modifiedMap = new Map();
    const changes = [];

    // Build maps for original and modified arrays to track indices and hidden status
    originalArray.forEach((item, index) => originalMap.set(item.id, { index, hidden: item.hidden }));
    modifiedArray.forEach((item, index) => modifiedMap.set(item.id, { index, hidden: item.hidden }));

    if (type === actionType.ADDED || type === actionType.MOVED) {
      // Process additions and movements in one pass
      for (let [index, item] of modifiedArray.entries()) {
        const original = originalMap.get(item.id);

        if (!original && !item.hidden && type === actionType.ADDED) {
          // Added items
          return [
            {
              type: actionType.ADDED,
              index,
              value: item['serial-number']
            }
          ];
        } else if (original && type === actionType.MOVED && original.index !== index) {
          // Moved items
          return [
            {
              type: actionType.MOVED,
              fromIndex: original.index,
              toIndex: index,
              movement: index - original.index,
              value: item['serial-number']
            }
          ];
        }
      }
    }

    if (type === actionType.HIDE || type === actionType.UNHIDE) {
      // Directly find the item if it's about hiding/unhiding
      const item = modifiedArray.find((item) => item.id === id);
      if (item) {
        return [
          {
            type: type,
            index: modifiedMap.get(id).index,
            value: item['serial-number']
          }
        ];
      }
    }

    if (type === actionType.REMOVED) {
      // Check for removed items
      for (let [index, item] of originalArray.entries()) {
        if (!modifiedMap.has(item.id) && !item.hidden) {
          return [
            {
              type: actionType.REMOVED,
              index,
              value: item['serial-number']
            }
          ];
        }
      }
    }

    // If no changes were detected for the given type
    return [];
  };

  const addTrademark = async () => {
    if (!search?.subscription) {
      setOpenSubscribeModal(true);
    } else {
      let prefixes = ['']; // Directly try with the original regID first
      if (regID?.length == 7) {
        prefixes = ['rn', 'ir']; // Add prefixes to try if regID length is 7
      }

      let data = null;
      for (const prefix of prefixes) {
        const testRegID = prefix + regID;
        data = await getApcByID(testRegID);

        // Break if data is found or if there is no server error (status <= 200)
        if (data !== undefined || data?.status <= 200) {
          break;
        }
      }
      // Assuming you want to show the toast when there's no data or if there's an error
      if (!data || data?.status > 200) {
        toast.error('No results were found', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
      } else {
        setTrademarkToAdd(data);
      }
    }
  };

  const saveReport = async (search) => {
    try {
      await axiosInstance
        .post('/api/report-data', { _id: props.auth.user.watchReportID })
        .then(async (res) => {
          let firstPartReplacedData = res.data.data.part1;
          let secondPartReplacedData = res.data.data.part2;

          const urlPattern = /https:\/\/confusability-assets\.s3\.us-east-2\.amazonaws\.com\/apple-touch-icon\.png/g;
          const regexDate = /<confusability-date([^>]*)>([\s\S]*?)<\/confusability-date>/g;
          const regexFirstName = /<confusability-firstname([^>]*)>([\s\S]*?)<\/confusability-firstname>/g;
          const regexLastName = /<confusability-lastname([^>]*)>([\s\S]*?)<\/confusability-lastname>/g;
          const regexCompany = /<confusability-company([^>]*)>([\s\S]*?)<\/confusability-company>/g;
          const regexCountry = /<confusability-country([^>]*)>([\s\S]*?)<\/confusability-country>/g;
          const regexCity = /<confusability-city([^>]*)>([\s\S]*?)<\/confusability-city>/g;
          const regexZipCode = /<confusability-zipcode([^>]*)>([\s\S]*?)<\/confusability-zipcode>/g;
          const regexAddress = /<confusability-address([^>]*)>([\s\S]*?)<\/confusability-address>/g;
          const regexPhone = /<confusability-phone([^>]*)>([\s\S]*?)<\/confusability-phone>/g;
          const regexEmail = /<confusability-email([^>]*)>([\s\S]*?)<\/confusability-email>/g;
          const regexWebsite = /<confusability-website([^>]*)>([\s\S]*?)<\/confusability-website>/g;
          const regexState = /<confusability-state([^>]*)>([\s\S]*?)<\/confusability-state>/g;
          const USER_FIRSTNAME_VALUE = props.auth.user.firstName;
          const USER_EMAIL_VALUE = props.auth.user.email;
          const USER_LASTNAME_VALUE = props.auth.user.lastName;
          const USER_PHONE_VALUE = props.auth.user.phone;
          const USER_COUNTRY_VALUE = props.auth.user.country;
          const USER_ADDRESS_VALUE = props.auth.user.address;
          const WEBSITE_VALUE = props.auth.user.website;
          const CITY_VALUE = props.auth.user.city;
          const STATE_VALUE = props.auth.user.state;
          const COMPANY_VALUE = props.auth.user.company;
          const ZIPCODE_VALUE = props.auth.user.zipCode;
          const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ];

          const date = new Date();
          const monthName = months[date.getMonth()];

          const year = date.getFullYear();

          const dayOfMonth = date.getDate().toString().padStart(2, '0');
          const DD_VALUE = dayOfMonth;
          const YYYY_VALUE = year;
          const MONTH_NAME_VALUE = monthName;
          try {
            firstPartReplacedData = firstPartReplacedData.replace(urlPattern, search?.imageURL || '');

            firstPartReplacedData = firstPartReplacedData.replace(
              'https://confusability-assets.s3.us-east-2.amazonaws.com/apple-touch-icon.png',
              search?.imageURL || ''
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              'https://confusability-assets.s3.us-east-2.amazonaws.com/apple-touch-icon.png',
              search?.imageURL
            );

            firstPartReplacedData = firstPartReplacedData.replace(
              regexDate,
              `<confusability-date$1>${MONTH_NAME_VALUE} ${DD_VALUE}, ${YYYY_VALUE}</confusability-date>` || ''
            );

            firstPartReplacedData = firstPartReplacedData.replace(
              regexCity,
              `<confusability-city$1>${CITY_VALUE}</confusability-city>` || ''
            );

            firstPartReplacedData = firstPartReplacedData.replace(
              regexState,
              `<confusability-state$1>${STATE_VALUE}</confusability-state>` || ''
            );

            firstPartReplacedData = firstPartReplacedData.replace(
              regexZipCode,
              `<confusability-zipcode$1>${ZIPCODE_VALUE}</confusability-zipcode>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexFirstName,
              `<confusability-firstname$1>${USER_FIRSTNAME_VALUE}</confusability-firstname>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexLastName,
              `<confusability-lastname$1>${USER_LASTNAME_VALUE}</confusability-lastname>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexCompany,
              `<confusability-company$1>${COMPANY_VALUE}</confusability-company>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexCountry,
              `<confusability-country$1>${USER_COUNTRY_VALUE}</confusability-country>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexAddress,
              `<confusability-address$1>${USER_ADDRESS_VALUE}</confusability-address>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexPhone,
              `<confusability-phone$1>${USER_PHONE_VALUE}</confusability-phone>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexEmail,
              `<confusability-email$1>${USER_EMAIL_VALUE}</confusability-email>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexWebsite,
              `<confusability-website$1>${WEBSITE_VALUE}</confusability-website>` || ''
            );
            firstPartReplacedData = firstPartReplacedData.replace(
              regexDate,
              `<confusability-date$1>${MONTH_NAME_VALUE} ${DD_VALUE}, ${YYYY_VALUE}</confusability-date>` || ''
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexDate,
              `<confusability-date$1>${MONTH_NAME_VALUE} ${DD_VALUE}, ${YYYY_VALUE}</confusability-date>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(regexCity, `<confusability-city$1>${CITY_VALUE}</confusability-city>`);
            secondPartReplacedData = secondPartReplacedData.replace(
              regexZipCode,
              `<confusability-zipcode$1>${ZIPCODE_VALUE}</confusability-zipcode>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexFirstName,
              `<confusability-firstname$1>${USER_FIRSTNAME_VALUE}</confusability-firstname>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexState,
              `<confusability-state$1>${STATE_VALUE}</confusability-state>` || ''
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexLastName,
              `<confusability-lastname$1>${USER_LASTNAME_VALUE}</confusability-lastname>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexCompany,
              `<confusability-company$1>${COMPANY_VALUE}</confusability-company>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexCountry,
              `<confusability-country$1>${USER_COUNTRY_VALUE}</confusability-country>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexAddress,
              `<confusability-address$1>${USER_ADDRESS_VALUE}</confusability-address>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexPhone,
              `<confusability-phone$1>${USER_PHONE_VALUE}</confusability-phone>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexEmail,
              `<confusability-email$1>${USER_EMAIL_VALUE}</confusability-email>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexWebsite,
              `<confusability-website$1>${WEBSITE_VALUE}</confusability-website>`
            );
            secondPartReplacedData = secondPartReplacedData.replace(
              regexDate,
              `<confusability-date$1>${MONTH_NAME_VALUE} ${DD_VALUE}, ${YYYY_VALUE}</confusability-date>`
            );
          } catch (error) {}

          const data = {
            ...res.data,
            data: { ...res.data.data, part1: firstPartReplacedData, part2: secondPartReplacedData },
            searchID: search._id
          };

          const reportResult = await addReport(data);

          const resData = reportResult.data.data;

          await updateWatch(search._id, { reportID: resData._id });
          setLoading(false);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const onChangeTable = async (dataTable, type, itemId) => {
    if (!search?.subscription) {
    } else {
      const changes = compareArrays(originalData, dataTable, type, itemId);
      setDataTable(dataTable); // This is synchronous and doesn't need to be awaited

      try {
        // If these two functions can run independently, consider Promise.all for efficiency
        await Promise.all([addTableLogs(search._id, changes), updateWatch(search._id, { table: dataTable })]);
      } catch (error) {}
    }
  };

  const updateFrequency = async (frequency) => {
    try {
      await updateWatchFrequency(props.auth.user.id, frequency);
      setEmailFrequency(frequency);
      getUser({ _id: props.auth.user.id });
    } catch (error) {}

    // await fetchData();
  };

  const optionStyle = (currentValue, optionValue) => ({
    cursor: 'pointer',
    fontWeight: currentValue === optionValue && 'bold',
    color: currentValue === optionValue ? theme.palette.primary.main : 'grey',
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  });

  const handleOpenSubscribeModal = () => {
    setOpenSubscribeModal(true);
  };

  const handleClose = () => {
    setIframe(false);
    setOpenSubscribeModal(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const history = await fetchWatchedSearches(props.auth.user.id);
      if (history) {
        setHistory(history);
        const defaultID = id ? id : history[0]._id;
        const selectedSearch = history.filter((data) => data._id === defaultID);
        if (selectedSearch?.length > 0) {
          setSearch(selectedSearch[0]);
          setEmailFrequency(props.auth.user.frequency || 'everyWednesday');
        }

        // Find the index of the selected item in the filtered array
        const index = history.findIndex((data) => data._id === defaultID);
        setSelectedCard(index);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchDataLatest = async () => {
    let toastId = null; // Initialize a variable to hold your toast ID

    try {
      setLoadingNewData(true);
      // Create a toast with a unique ID and store it
      toastId = toast.success('Checking for new data…', {
        position: 'top-right',
        autoClose: false, // Set autoClose to false
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });

      const history = await fetchWatchedSearchesLatestData(props.auth.user.id);
      if (history) {
        setLoadingNewData(false);
        setHistory(history);
        // Manually close the toast once the data is fetched
        toast.dismiss(toastId);
      }
    } catch (error) {
      toast.update(toastId, { type: toast.TYPE.ERROR, render: 'An error occurred while fetching data', autoClose: 5000 });
    } finally {
      if (toastId && !history) {
        toast.dismiss(toastId); // Ensure the toast is dismissed in case of no history or errors
      }
    }
  };

  useEffect(() => {
    fetchDataLatest();
  }, []);

  useEffect(() => {
    fetchData();
  }, [props]);

  const handleSelect = (data, index) => {
    // setSelectedCard(index);
    // setSearch(history[index]);
    // setSelectedCard(index);
    // setEmailFrequency(history[index].frequency);
    navigate('/watch/' + history[index]._id);
  };

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setCroppedImageUrl(null);
  };

  const uploadFile = async () => {
    if (croppedImageUrl) {
      try {
        setLoading(true);
        let formData = new FormData();
        formData.append(
          'file',
          dataURLtoFile(croppedImageUrl.result ? croppedImageUrl.result : croppedImageUrl, `${new Date().getTime()}.`)
        );

        let data = {
          formData: formData
        };

        const imageUrl = await uploadImage(data);

        setLoading(true);
        let newWatch = await createWatch(props.auth.user.id, '', imageUrl, emailFrequency);

        await saveReport(newWatch);
        navigate('/watch/' + newWatch._id);
        await fetchData();
        handleCloseUpload();
        setOpenUpload(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      alert('Please ensure that you upload an image.');
    }
  };

  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const tempExt = mime.split('/');
    const extension = tempExt?.length > 1 ? tempExt[1] : tempExt[0];
    const bstr = atob(arr[1]);
    let n = bstr?.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename + extension, { type: mime });
  };

  const handleOpenWatchingSince = () => {
    setOpenWatchingSince(true);
  };

  const handleWatchingSince = () => {
    setOpenWatchingSince(false);
  };

  function dateToString(dateProp) {
    let date = new Date(dateProp);
    // Array of month names
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // Get the day of the month and pad with leading zero if necessary
    var day = date.getDate();
    day = day < 10 ? '0' + day : day;

    // Get the full month name
    var month = months[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Format the date string
    var formattedDate = month + ' ' + day + ', ' + year;

    return formattedDate;
  }

  const explainPage = {
    title:
      'Our Watch Service monitors the USPTO database for new logos that may be visually similar to your own and notifies you of our findings. There is an annual subscription of $20 per logo.',
    footer: <Typography></Typography>,
    li: [
      <>
        {' '}
        <strong>Add the logos you want us to watch, </strong> including any variants of your logos.
      </>,
      <>
        {' '}
        <strong>Set your email preference.</strong>
      </>,
      <>
        <strong>The Watch Service begins</strong> with all new additions to the USPTO as of the first day of your subscription, which you
        can see by clicking <span style={{ color: 'blue', textDecoration: 'underline' }}>Watching Since</span>. The results are updated in
        real time.
      </>,
      <>
        <strong>An email</strong> summarizing new results is also sent per your email preference.
      </>,
      <>
        <strong>Archive</strong> unconcerning results by clicking{' '}
        <span style={{ verticalAlign: 'middle' }}>
          <ArchiveIcon color={'primary'} />
        </span>
        . You can always unarchive any logo.
      </>
    ]
  };

  useEffect(() => {
    if (search) {
      setDataTable(search?.table);
      const { usFilters, internationalFilters, statusFilters, designSearchFilters } = search;

      usFilters && setFilters(usFilters);
      internationalFilters && setInternationalFilters(internationalFilters);
      statusFilters && setStatusFilters(statusFilters);
      designSearchFilters && setDesignSearchFilters(designSearchFilters);
      setLabelText(search?.label ? search?.label : search?._id);
    }
  }, [search]);

  const filterData = (selectedUsFilters, selectedInternationalFilters, selectedStatusFilters, selectedDesignSearchFilters) => {
    // Early return if no filters are selected
    if (
      selectedUsFilters?.length === 0 &&
      selectedInternationalFilters?.length === 0 &&
      selectedStatusFilters?.length === 0 &&
      selectedDesignSearchFilters?.length === 0
    ) {
      const unfilteredData = dataTable?.map((item) => ({ ...item, isFiltered: false }));
      setDataTable(unfilteredData);
      setOriginalData(unfilteredData);
      setFilters([]);
      setInternationalFilters([]);
      setStatusFilters([]);
      setDesignSearchFilters([]);
      return; // Ensure the function exits here
    }

    const filteredData = dataTable?.map((item) => {
      // Check each filter category for a match
      const hasUsMatchingFilter = selectedUsFilters.some((filter) => item.usClasses?.some((classObj) => classObj.code === filter.label));
      const hasInternationalMatchingFilter = selectedInternationalFilters.some((filter) =>
        item.internationalClasses?.some((classObj) => classObj.code === filter.label)
      );
      const hasStatusMatchingFilter = selectedStatusFilters.some((filter) => item.status?.status === filter.label);
      const hasDesignMatchingFilter = selectedDesignSearchFilters.some((filter) =>
        item.designSearchList?.some((classObj) => classObj.code === filter.label)
      );

      // An item is considered filtered if it matches any of the selected filters
      const isFiltered = hasUsMatchingFilter || hasInternationalMatchingFilter || hasStatusMatchingFilter || hasDesignMatchingFilter;
      console.log({ isFiltered });
      console.log(selectedUsFilters, selectedInternationalFilters, selectedStatusFilters, selectedDesignSearchFilters);

      return { ...item, isFiltered: !isFiltered };
    });

    // Update state with the filtered data
    setDataTable(filteredData);
    setOriginalData(filteredData); // Consider if you really want to mirror dataTable and originalData
    setFilters(selectedUsFilters);
    setInternationalFilters(selectedInternationalFilters);
    setStatusFilters(selectedStatusFilters);
    setDesignSearchFilters(selectedDesignSearchFilters);
    saveFilters(selectedUsFilters, selectedInternationalFilters, filteredData, selectedStatusFilters, selectedDesignSearchFilters);
  };

  const saveFilters = async (usFilters, internationalFilters, filteredData, statusFilters, designSearchFilters) => {
    if (!search?.subscription) {
      setOpenSubscribeModal(true);
    } else {
      await updateWatch(search._id, {
        usFilters,
        internationalFilters,
        table: filteredData,
        statusFilters, // Ensure statusFilters is defined or passed to this function
        designSearchFilters
      });
      console.log('updated');
    }
  };

  const onEditClick = () => {
    if (!search?.subscription) {
      setOpenSubscribeModal(true);
    } else {
      navigate(`/report/${search?._id}`, { state: { type: 'watch' } });
    }
  };

  const save = async (label) => {
    if (!search?.subscription) {
      setOpenSubscribeModal(true);
    } else {
      setLabelText(label);

      await updateWatch(search?._id, { label });
    }
  };

  // Directly use exportPDF for PDF generation without an async wrapper
  const Pdf = () => exportWatchPDF(props.auth.user.id, search?._id);

  // Direct usage of exportWord for Word document generation
  const Word = () => exportWatchWord(props.auth.user.id, search?._id);

  return (
    <MainCard>
      <ToastContainer />
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'white' }} open={loading}>
        <CircularProgress color="primary" sx={{ mr: 2 }} />
        Loading ...
      </Backdrop>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '110vh' // Set your width here
            }
          }
        }}
        open={iframe}
        onClose={handleClose}
      >
        <DialogTitle>{`https://tsdr.uspto.gov/#caseNumber=${iframe}&caseType=DEFAULT&searchType=statusSearch`}</DialogTitle>
        <DialogContent sx={{ height: '90vh' }}>
          <Iframe
            width="100%"
            height="100%"
            url={`https://tsdr.uspto.gov/#caseNumber=${iframe}&caseType=DEFAULT&searchType=statusSearch`}
            style={{ width: '100%', height: '100%', backgroundColor: 'red' }}
            frameBorder="0" // Add frameBorder to remove iframe border (optional)
          />
        </DialogContent>
      </Dialog>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%'
            }
          }
        }}
        open={openUpload}
        onClose={handleCloseUpload}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: 'bold', ml: '10px' }}>
                Add a logo to the Watch list
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                edge="end"
                aria-label="close"
                onClick={() => {
                  setCroppedImageUrl(null);
                  handleCloseUpload();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70%' }}>
            <Box sx={{ width: '100%', maxWidth: '700px' }}>
              <ModernImageInput
                setImage={setImage}
                croppedImageUrl={croppedImageUrl}
                setCroppedImageUrl={setCroppedImageUrl}
                image={image?.result ? image?.result : image}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 5 }}>
                {' '}
                <Button
                  disabled={loading}
                  sx={{ width: '100%', maxWidth: '700px' }}
                  variant="contained"
                  onClick={uploadFile}
                  endIcon={<EyeOutlined />}
                >
                  {' '}
                  Add to Watch List
                </Button>
              </Box>
              <p style={{ textAlign: 'center', fontSize: '10px' }}>
                {' '}
                This service is intended as a support tool to complement, and not to replace, an exhaustive search done by a trademark
                attorney. Information provided here does not constitute legal advice or create an attorney-client relationship. Please
                consult with a trademark attorney before making important decisions.
              </p>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '450px'
            }
          }
        }}
        open={openWatchingSince}
        onClose={handleWatchingSince}
      >
        <DialogContent sx={{ minHeight: '30vh' }}>
          <DialogTitle>
            <Grid container spacing={5} marginBottom={3}>
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Logo
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Watching Since
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          {history?.map(
            (data, index) =>
              data.subscription && (
                <Grid container spacing={5} key={index}>
                  <Grid item xs={6} display={'flex'} alignItems={'center'}>
                    <SelectableCard notSelectable imageSrc={data.imageURL} onSelect={() => {}} />
                  </Grid>
                  <Grid item xs={6} display={'flex'} alignItems={'center'}>
                    {dateToString(data.createdAt)}
                  </Grid>
                </Grid>
              )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWatchingSince}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '60vh',
              padding: 0
            }
          }
        }}
        open={openSubscribeModal}
        onClose={handleClose}
      >
        {/* TODO: Anthony check <DialogContent sx={{ width: '250v', height: '90vh' }}> , to make increase width */}
        {/* <DialogTitle>{`You need to subscribe`}</DialogTitle> */}
        <DialogContent sx={{ width: '100%', padding: 0, margin: 0 }}>
          <WatchSubscribeCard popup watch handleClose={handleClose} url={search?.paymentLink} />
        </DialogContent>
      </Dialog>
      <Grid container sx={{ display: 'flex' }}>
        <Grid item xs={12} md={5}>
          <PageExplanation explainPage={explainPage} />
          {/* <PageExplanation explainPage={explainPage} /> */}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Email Me:
          </Typography>
        </Grid>
        <Grid item onClick={() => updateFrequency('everyWednesday')} style={optionStyle(emailFrequency, 'everyWednesday')}>
          Every Wednesday
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ color: 'grey' }}>
            |
          </Typography>
        </Grid>
        <Grid item onClick={() => updateFrequency('thirdDayOfMonth')} style={optionStyle(emailFrequency, 'thirdDayOfMonth')}>
          On the Third Day of Every Month
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ color: 'grey' }}>
            |
          </Typography>
        </Grid>
        <Grid item onClick={() => updateFrequency('never')} style={optionStyle(emailFrequency, 'never')}>
          Never
        </Grid>
      </Grid>
      {search && (
        <Box style={{ color: theme.palette.primary.main, width: '100%' }} display={'flex'} justifyContent={'end'}>
          <Typography onClick={handleOpenWatchingSince} sx={{ cursor: 'pointer', width: 'auto' }}>
            Watching Since
          </Typography>
        </Box>
      )}
      <Grid
        spacing={2}
        display={'flex'}
        mt={2}
        sx={{
          overflowX: 'auto',
          display: 'flex',
          '&::-webkit-scrollbar': {
            display: 'none' // Hides the scrollbar for WebKit browsers
          },
          scrollbarWidth: 'none', // Hides the scrollbar for Firefox
          msOverflowStyle: 'none' // Hides the scrollbar for IE 10+
        }}
      >
        {history?.map((data, index) => (
          <Grid mr={2} item key={index}>
            <SelectableCard imageSrc={data.imageURL} onSelect={() => handleSelect(data, index)} selected={selectedCard === index} />
          </Grid>
        ))}
        <Grid item>
          <SelectableCard add onSelect={handleOpenUpload} />
        </Grid>
      </Grid>
      {history?.length != 0 && (
        <>
          <Box sx={{ display: 'flex', height: 'auto', alignItems: 'center' }}>
            <EditableLabel labelText={labelText} onChange={save} />
          </Box>
          <Grid container>
            <Grid container xs={12} md={5}>
              <Grid sx={{ mt: 2 }} container justifyContent={'center'}>
                <Grid xs={7} md={6} item display={'flex'} alignItems={'center'}>
                  <TextInput placeholder={'Registration or Serial Number'} value={regID} onChange={setRegID} />
                </Grid>
                <Grid xs={5} md={6} item display={'flex'} alignItems={'center'}>
                  <Button title="" sx={{ height: '100%', height: '40px' }} variant={'outlined'} onClick={addTrademark}>
                    + Add to list
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={7} item>
              <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end', width: '100%' }}>
                {' '}
                <Box xs={12} sm={12} item>
                  <Button sx={{ marginLeft: -1 }} onClick={onEditClick}>
                    {!search?.reportID ? 'Edit Report' : 'Edit Report'}
                  </Button>

                  <br />

                  <Grid container sx={{ display: 'flex' }} color="primary.main">
                    <a sx={{ width: '100%' }} style={{ cursor: 'pointer' }} color="primary.main" onClick={Pdf}>
                      {'PDF'}
                    </a>
                    <a style={{ marginLeft: 5, marginRight: 5 }}>|</a>
                    <a onClick={Word} style={{ cursor: 'pointer' }}>
                      {'Word'}
                    </a>
                  </Grid>

                  <br />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <UsClassesFilterModal
              isSubscribed={!!search?.subscription}
              selectedFilters={usFilters}
              setFilters={setFilters}
              onChange={(filters) => {
                filterData(filters, internationalFilters, statusFilters, designSearchFilters);
              }}
              data={dataTable}
              search={search}
            />
            <InternationalClassesFilterModal
              isSubscribed={!!search?.subscription}
              selectedFilters={internationalFilters}
              setFilters={setInternationalFilters}
              onChange={(filters) => {
                filterData(usFilters, filters, statusFilters, designSearchFilters);
              }}
              data={dataTable}
              search={search}
            />
            <DesignSearchListFilterModal
              isSubscribed={!!search?.subscription}
              selectedFilters={designSearchFilters}
              setFilters={setDesignSearchFilters}
              onChange={(filters) => {
                filterData(usFilters, internationalFilters, statusFilters, filters);
              }}
              data={dataTable}
              search={search}
            />
            <StatusFilterModal
              isSubscribed={!!search?.subscription}
              search={search}
              selectedFilters={statusFilters}
              setFilters={setStatusFilters}
              onChange={(filters) => {
                filterData(usFilters, internationalFilters, filters, designSearchFilters);
              }}
              data={dataTable}
            />
          </Box>

          <Grid container spacing={2} mt={1} sx={{ mb: 3 }}>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Categories:
              </Typography>
            </Grid>
            <Grid item onClick={() => setCategory('watchList')} style={optionStyle(category, 'watchList')}>
              {/* {category === 'watchList' && <CheckCircleOutlineIcon color="primary" />} */}
              Watch List
            </Grid>
            <Grid item>
              <Typography variant="h6" sx={{ color: 'grey' }}>
                |
              </Typography>
            </Grid>
            <Grid item onClick={() => setCategory('archived')} style={optionStyle(category, 'archived')}>
              {/* {category === 'archived' && <CheckCircleOutlineIcon color="primary" />} */}
              Archived
            </Grid>
          </Grid>

          <Box sx={{ mt: 2 }}>
            {!search?.subscription && (search?.table?.length === 0 || !search?.table) ? (
              search && <WatchSubscribeCard watchID={search?._id} url={search?.paymentLink} />
            ) : (
              <DragDropTable
                onChange={onChangeTable}
                originalData={dataTable}
                columns={columns}
                archive
                showArchived={category === 'watchList'}
                removeActions
                trademarkToAdd={trademarkToAdd}
                setIframe={setIframe}
                iframe={iframe}
              />
            )}
          </Box>
        </>
      )}
    </MainCard>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Watch);

const columns = [
  { field: 'image-url', header: 'Images' },
  { field: 'id', header: 'Serial No.' },
  { field: 'status', header: 'Status' },
  { field: 'usClasses', header: 'US' },
  { field: 'internationalClasses', header: 'Int.' },
  { field: 'designSearchList', header: 'Design Codes' },
  { field: 'case-file-header.registration-date', header: 'Reg. Date' },
  { field: 'case-file-owners.case-file-owner[0].party-name', header: 'Registrant' },
  { field: 'case-file-statements.case-file-statement[0].text', header: 'Info' }
];

const actionType = {
  MOVED: 'moved',
  REMOVED: 'removed',
  ADDED: 'added',
  UNHIDE: 'unhide',
  HIDE: 'hide'
};
