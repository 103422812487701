import { Chip } from '@mui/material';
import React, { useEffect } from 'react';
import StarIcon from '@mui/icons-material/Star';
import { useTheme } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import { connect } from 'react-redux';

const ChipFilter = ({
  label,
  onClick,
  description,
  rate,
  leftIcon,
  defaultSelected,
  selectedProp,
  selectedPropAll,
  row,
  all,
  isSubscribed
}) => {
  const [selected, setSelected] = React.useState(false);
  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);
  const theme = useTheme();

  const chipStyle = {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    borderWidth: 1,
    cursor: 'pointer',
    margin: 2,
    marginLeft: 0,
    fontSize: '11px',
    padding: '0px',
    borderRadius: '15px',
    height: '25px'
  };

  const chipStyleSelected = {
    minWidth: 0,
    padding: '0px',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderWidth: 1,
    cursor: 'pointer',
    margin: 2,
    marginLeft: 0,
    borderRadius: '15px',
    fontSize: '11px',
    height: '25px'
  };

  const rateStyle = {
    color: theme.palette.primary.main,
    marginLeft: '-7px',
    width: '20px'
  };

  const rateStyleSelected = {
    color: theme.palette.primary.main,
    marginLeft: '-7px',
    width: '20px'
  };
  useEffect(() => {
    if (selectedProp && !all) {
      setSelected(defaultSelected);
    }
    if (all) {
      setSelected(selectedPropAll);
    }
  }, [selectedPropAll, defaultSelected]);

  const onClickChip = () => {
    if (!(all && selected)) {
      onClick(!selected, row);
      if (isSubscribed) {
        setSelected(!selected);
      }
    } else {
      onClick(selected, row);
    }
  };

  return (
    <Tooltip title={description} placement="top">
      <Chip
        onDelete={rate && onClickChip}
        avatar={leftIcon}
        deleteIcon={<StarIcon style={selected ? rateStyleSelected : rateStyle} />}
        onClick={onClickChip}
        label={label}
        style={selected ? chipStyleSelected : chipStyle}
        variant={'outlined'}
      />
    </Tooltip>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ChipFilter);
