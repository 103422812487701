/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ChipFilter from '../chip-filter';
import { connect } from 'react-redux';

function MultiSelect({ listOfFilterProp, onFilterChange, rate, selectedFilters, setFilters, isSubscribed }) {
  const uniqueFiltersFunction = () => {
    const unique = [];
    const encounteredLabels = {};
    listOfFilterProp.forEach((filter) => {
      const { label } = filter;
      if (!encounteredLabels[label]) {
        unique.push(filter);
        encounteredLabels[label] = true;
      }
    });
    return unique;
  };

  const [selectedAll, setSelectedAll] = useState(true);
  const [uniqueFilters, setUniqueFilters] = useState([]);

  const filterData = () => {
    const filters = uniqueFiltersFunction();

    const sortedData = filters?.sort((a, b) => {
      const numA = parseInt(a.label, 10);
      const numB = parseInt(b.label, 10);
      return numA - numB;
    });
    return sortedData;
  };

  useEffect(() => {
    setUniqueFilters(filterData());
  }, [selectedFilters, listOfFilterProp]);

  useEffect(() => {
    setSelectedAll(selectedFilters?.length === 0);
  }, [selectedFilters, listOfFilterProp]);

  useEffect(() => {}, [selectedFilters, selectedAll, listOfFilterProp]);

  const onChipFilterClick = (selected, row) => {
    if (selected) {
      if (isSubscribed) onFilterChange([...selectedFilters, row]);
      else onFilterChange(selectedFilters);
      if (isSubscribed) setSelectedAll(false);
    } else {
      const values = selectedFilters.filter((value) => value.label !== row.label);

      if (isSubscribed) onFilterChange(values);
      else onFilterChange(selectedFilters);
      if (isSubscribed) {
        setSelectedAll(values?.length === 0);
        if (values?.length === 0) {
          const filters = filterData();
          let notSelectedFilters = filters.map((data) => {
            return {
              ...data,
              isSelected: false
            };
          });
          setUniqueFilters(notSelectedFilters);
        }
      }
    }
  };

  const label = (row) => {
    return (row.label + ' ' + row.description).substring(0, 15) + ((row.label + ' ' + row.description)?.length > 15 ? '...' : '');
  };

  return (
    <>
      {uniqueFilters?.length > 0 ? (
        <div>
          <ChipFilter
            label={'All'}
            all
            selectedPropAll={selectedAll}
            onClick={() => {
              if (!selectedAll && isSubscribed) {
                onFilterChange([]);
                setFilters([]);
                setSelectedAll(true);
                const filters = filterData();
                let notSelectedFilters = filters.map((data) => {
                  return {
                    ...data,
                    isSelected: false
                  };
                });
                setUniqueFilters(notSelectedFilters);
              }
            }}
          />
          {uniqueFilters.map((row, i) => {
            if (row.label !== undefined) {
              return (
                <ChipFilter
                  isSubscribed={isSubscribed}
                  rate={rate}
                  row={row}
                  description={row.description}
                  defaultSelected={row.isSelected}
                  selectedProp={selectedFilters?.length === 0}
                  key={i}
                  label={label(row)}
                  onClick={onChipFilterClick}
                />
              );
            }
            return null;
          })}
        </div>
      ) : (
        <div>No Filters Available</div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(MultiSelect);
