/* eslint-disable */
import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';
import AuthWrapper from 'pages/authentication/AuthWrapper';
import { connect } from 'react-redux';

const termsContent = `


**1. Description of Service**

The Website provides a search engine for trademark images made available by the United States Patent and Trademark Office ("the Service"). The Service allows users to search for and access a set of registered and applicant trademark images for a fee. Users can be located anywhere globally, subject to compliance with applicable laws and regulations.


**2. User Accounts**

**2.1 Account Creation:** In order to access and use the Service, you must create an account. You agree to provide accurate, complete, and up-to-date information during the registration process. You are solely responsible for maintaining the confidentiality of your account information, including your username and password. You agree to notify us immediately of any unauthorized use of your account.

**2.2 Account Usage:** You are solely responsible for all activities that occur under your account. You agree not to share your account credentials with others or allow unauthorized access to the Service. You acknowledge that we are not responsible for any losses or damages arising from unauthorized use of your account.


**3. Fees and Payments**

**3.1 Pricing:** The use of the Service is subject to the payment of applicable fees. The pricing for the Service is clearly stated on the Website, and you agree to pay the fees associated with your selected plan or usage.

**3.2 Payment Method:** We may offer various payment methods for your convenience. You agree to provide accurate and valid payment information and authorize us to charge the specified amount to your selected payment method.

**3.3 Subscription and Billing:** If you choose a subscription-based plan, you agree to recurring billing at the specified intervals, unless you cancel your subscription prior to the next billing cycle. You are responsible for timely cancellation if you no longer wish to continue the subscription.

**3.4 Refunds:** We may have refund policies in place, as outlined on the Website. Refund eligibility will be determined in accordance with these policies.


**4. Intellectual Property**

**4.1 Trademarks:** The trademark images made available through the Service are protected by intellectual property laws. All trademarks displayed on the Website are the property of their respective owners. You may not use, reproduce, distribute, or modify these trademarks without obtaining the necessary permissions.

**4.2 User Content:** By using the Service, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, and distribute any content you submit or display through the Website for the purpose of providing and promoting the Service.


**5. Limitation of Liability**

**5.1 Disclaimer:** The Service is provided on an "as-is" and "as available" basis. We make no warranties or representations regarding the accuracy, reliability, or completeness of the Service. We disclaim all warranties, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose, and non-infringement.

**5.2 Limitation of Liability:** To the maximum extent permitted by applicable law, we shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Service, even if we have been advised of the possibility of such damages.


**6. Governing Law and Dispute Resolution**

These Terms shall be governed by and construed in accordance with the laws of the United States, without regard to its conflict of laws principles. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in the United States.


**7. Modification of Terms**

We reserve the right to modify these Terms at any time without prior notice. Any changes to the Terms will be effective immediately upon posting on the Website. Your continued use of the Service after the posting of revised Terms constitutes your acceptance of such changes.


**8. Termination**

We may, in our sole discretion, terminate or suspend your access to the Service without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the Service will cease immediately.


**9. Severability**

If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.


**10. Entire Agreement**

These Terms constitute the entire agreement between you and us regarding your use of the Service and supersede all prior agreements and understandings.


If you have any questions or concerns regarding these Terms, please contact us at support@confusability.ai.
`;

const privacyContent = `
  This is the Privacy Policy page. Add your Privacy Policy content here.
`;

const TermsOfService = (props) => {
  return (
    <AuthWrapper large removeLogo={props?.auth?.user?.email}>
      <Container>
        <Box my={4} p={3} borderRadius={8}>
          <Typography variant="h4" component="h1" gutterBottom color="#3f51b5">
            Terms of Service
          </Typography>
          <Divider />
          <Box mt={2}>
            {termsContent.split('\n\n').map((paragraph, index) => (
              <Typography key={index} variant="body1" paragraph>
                {paragraph.split('**').map((text, index) => (index % 2 === 1 ? <strong key={index}>{text}</strong> : text))}
              </Typography>
            ))}
          </Box>
        </Box>
      </Container>
    </AuthWrapper>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps)(TermsOfService);
