/* eslint-disable */
import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
// import RTLMainLayout from 'layout/RTLMainLayout';
import MainLayout from 'layout/MainLayout';
import EmailVerified from 'pages/email-verified/index';

const Watch = Loadable(lazy(() => import('pages/watch')));
const DashboardDefault = Loadable(lazy(() => import('pages/home')));

const History = Loadable(lazy(() => import('pages/history')));
const Profile = Loadable(lazy(() => import('pages/profile')));
const Results = Loadable(lazy(() => import('pages/results')));
const Subscribe = Loadable(lazy(() => import('pages/subscribe')));

const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const CustomizeReport = Loadable(lazy(() => import('pages/customize-report')));
const DefaultReport = Loadable(lazy(() => import('pages/default-report')));
const ForgetPassword = Loadable(lazy(() => import('pages/forget-password')));
const TermsOfService = Loadable(lazy(() => import('pages/terms-of-service')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/privacy-policy')));
const VerifyEmailPage = Loadable(lazy(() => import('pages/verify-email')));
const Contact = Loadable(lazy(() => import('pages/contact-us')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/contact',
      element: <Contact />
    },
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: '/reset-password',
      element: <ForgetPassword />
    },
    {
      path: '/subscribe',
      element: <Subscribe />
    },

    {
      path: 'search-results/:id',
      element: <Results />
    },
    {
      path: '/home',
      element: <DashboardDefault />
    },
    {
      path: 'history',
      element: <History />
    },
    {
      path: 'watch',
      element: <Watch />
    },
    {
      path: 'watch/:id',
      element: <Watch />
    },

    {
      path: 'email-verified',
      element: <EmailVerified />
    },

    {
      path: 'report/:id',
      element: <CustomizeReport />
    },
    {
      path: 'profile',
      element: <Profile />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: '/templates',
      element: <DefaultReport />
    },
    {
      path: '*',
      element: <DashboardDefault />
    },
    {
      path: 'terms-of-service',
      element: <TermsOfService />
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />
    },
    {
      path: 'verify-email',
      element: <VerifyEmailPage />
    }
  ]
};

export default MainRoutes;
