/* eslint-disable */
// React and third-party library imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { CountryDropdown } from 'react-country-region-selector';
import { ToastContainer, toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// MUI imports
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Container
} from '@mui/material';

// Actions imports (combine imports from the same module)
import { logoutUser, changePassword } from 'actions/authActions';
import { getUser, updateUser } from '../../actions/userActions';
import { cancelSubscription, cancelWatchSubscription, getSubscriptionByID } from 'actions/stripe';
import { requestData, requestDeletion } from '../../actions/request';
// Component and style imports
import MainCard from 'components/MainCard';
import AuthFooter from 'components/cards/AuthFooter';
import 'react-phone-input-2/lib/style.css';
import 'react-country-dropdown/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';

// Store import
import { store } from 'store';
import { fetchWatchedSearches } from 'actions/watch';
import { SelectableCard } from 'components/index';
import Info from 'components/info/index';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const Profile = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(() => theme.breakpoints.down('sm'));
  const [firstName, setFirstName] = useState(props.auth.user.firstName ? props.auth.user.firstName : '');
  const [lastName, setLastName] = useState(props.auth.user.lastName ? props.auth.user.lastName : '');
  const [country, setCountry] = useState(props.auth.user.country ? props.auth.user.country : '');
  const [address, setAddress] = useState(props.auth.user.address ? props.auth.user.address : '');
  const [state, setState] = useState(props.auth.user.state ? props.auth.user.state : '');
  const [phone, setPhone] = useState(props.auth.user.phone ? props.auth.user.phone : '');
  const [email, setEmail] = useState(props.auth.user.email ? props.auth.user.email : '');
  const [website, setWebsite] = useState(props.auth.user.website ? props.auth.user.website : '');

  const [zipCode, setZipCode] = useState(props.auth.user.zipCode ? props.auth.user.zipCode : '');
  const [company, setCompany] = useState(props.auth.user.company ? props.auth.user.company : '');
  const [city, setCity] = useState(props.auth.user.city ? props.auth.user.city : '');

  const [activeTab, setActiveTab] = useState(0);
  const [currentPassword, setCurrentPassword] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [subscription, setSubscription] = useState(null);
  const [locallyCancelledRequest, setLocallyCancelledRequest] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
  const [openCancelPlan, setOpenCancelPlan] = useState(false);
  const [openCancelWatchPlan, setOpenCancelWatchPlan] = useState(false);

  const [loading, setLoading] = useState(true);
  const [subscribedWatches, setSubscribedWatches] = useState([]);
  const dispatch = useDispatch();

  const removeWatchById = (watchId) => {
    setSubscribedWatches((currentWatches) =>
      currentWatches.map((watch) => {
        if (watch._id == watchId) {
          return { ...watch, subscriptionData: { ...watch.subscriptionData, cancelledByUser: true } };
        }
        return watch;
      })
    );
  };

  const deleteAccount = async () => {
    try {
      await requestDeletion(props.auth.user.id);
      await toast.success('Request Sent! Signing you out now.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      store.dispatch(logoutUser());
    } catch (error) {}
  };

  useEffect(() => {
    setLoading(true);
    const fetchSubscription = async () => {
      const subscriptionID = props.auth?.user?.user?.subscription?.subscriptionID;
      let watches = await fetchWatchedSearches(props?.auth?.user?.id);

      watches = watches?.filter((data) => !!data?.subscription?.subscriptionID);
      setSubscribedWatches(watches);
      if (subscriptionID) {
        try {
          const result = await getSubscriptionByID({ id: subscriptionID });
          if (result?.data) {
            setSubscription(result.data);
          } else {
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, [props.auth?.user?.user?.subscription?.subscriptionID]); // Dependency array

  const requestDataFunc = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      location.href = `${process.env.REACT_APP_API_URL}/export-data/${props.auth.user.id}?token=${token}`;
      await requestData(props.auth.user.id);
      toast.success('Request Sent!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    } catch (error) {}
  };

  const cancelSubscriptionFunc = async () => {
    setSubscription(null);
    setLocallyCancelledRequest(true);
    await cancelSubscription({ userID: props.auth.user.id });
  };

  const cancelWatchSubscriptionFunc = async () => {
    const watchID = openCancelWatchPlan;
    await cancelWatchSubscription({ watchID });
    removeWatchById(watchID);
    setOpenCancelWatchPlan(false);
  };

  const onClickPremium = async () => {
    location.href = '/subscribe';
  };

  const changePasswordFunc = async () => {
    const errors = {};

    // Validate the fields reEnteredPassword, newPassword, and currentPassword
    if (!reEnterPassword) {
      errors.reEnterPassword = 'Re-entered password is required';
    } else if (reEnterPassword.length < 8) {
      errors.reEnterPassword = 'Re-entered password should be at least 8 characters long';
    }

    if (!newPassword) {
      errors.newPassword = 'New password is required';
    } else if (newPassword.length < 8) {
      errors.newPassword = 'New password should be at least 8 characters long';
    }

    if (!currentPassword) {
      errors.currentPassword = 'Current password is required';
    } else if (currentPassword.length < 8) {
      errors.currentPassword = 'Current password should be at least 8 characters long';
    }
    if (Object.keys(errors).length > 0) {
      const errorMessages = Object.keys(errors).map((key) => errors[key]);

      errorMessages.forEach((error) => {
        toast.error(error, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
      });
    }

    try {
      changePassword({
        userID: props.auth.user.id,
        reEnteredPassword: reEnterPassword,
        newPassword: newPassword,
        currentPassword: currentPassword
      })
        .then((e) => {
          toast.success(e?.data?.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          });
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          });
        });
    } catch (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      // Handle error
    }
  };

  const onUserUpdate = async () => {
    try {
      await updateUser({
        _id: props.auth.user.id,
        firstName: firstName,
        lastName: lastName,
        country: country,
        address: address,
        state: state,
        email: email,
        website: website,
        phone: phone,
        zipCode: zipCode,
        company: company,
        city: city
      });
      await getUser({ ...props.auth.user, _id: props.auth.user.id });

      toast.success('Changes Saved!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    } catch (err) {
      toast.error('Something went wrong!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    }
  };

  useEffect(() => {
    getUser({ ...props.auth.user, _id: props.auth.user.id });
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const RequestConfirmationDialog = ({ title, contentText, handleAgree }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = () => {
      setOpen(false);
    };
    const handleAgreeAction = () => {
      setOpen(false);
      handleAgree();
    };

    return (
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleAgreeAction} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const DeleteAccountConfirmationDialog = ({ title, contentText, handleAgree }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
      setOpenDeleteAccount(false);
    };
    const handleAgreeAction = () => {
      handleAgree();
      setOpenDeleteAccount(false);
    };

    return (
      <Dialog fullScreen={fullScreen} open={openDeleteAccount} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleAgreeAction} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  function convertTimestampToLocalDate(timestampString) {
    // Create a new Date object with the provided timestamp
    const date = new Date(timestampString);

    // Define an array of month names
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    // Get the local date components
    const year = date.getFullYear();
    const month = monthNames[date.getMonth()]; // Get month name
    const day = date.getDate();

    // Format the local date string as "Month DD, YYYY"
    const localDateString = `${month} ${day}, ${year}`;

    return localDateString;
  }

  const CancelPlanConfirmationDialog = ({ title, contentText, handleAgree }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
      openCancelPlan, setOpenCancelPlan(false);
    };
    const handleCancelPlanAction = () => {
      handleAgree();
      setOpenCancelPlan(false);
    };

    return (
      <Dialog fullScreen={fullScreen} open={openCancelPlan} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No, I want to keep my plan
          </Button>
          <Button onClick={handleCancelPlanAction} autoFocus>
            Yes, cancel my plan
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const CancelWatchPlanConfirmationDialog = ({ title, contentText, handleAgree }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
      openCancelWatchPlan, setOpenCancelWatchPlan(false);
    };
    const handleCancelPlanAction = () => {
      handleAgree(openCancelWatchPlan);
    };

    return (
      <Dialog fullScreen={fullScreen} open={openCancelWatchPlan} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No, I want to keep my plan
          </Button>
          <Button onClick={handleCancelPlanAction} autoFocus>
            Yes, cancel my plan
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', minHeight: '90vh' }}>
      <MainCard id="page-content-wrapper">
        <ToastContainer />
        <RequestConfirmationDialog
          title={'Request Data'}
          contentText={'Are you sure you want to request your data?'}
          handleAgree={requestDataFunc}
        />
        <DeleteAccountConfirmationDialog
          title={'Delete Account'}
          contentText={'Are you sure you want to delete your account?'}
          handleAgree={deleteAccount}
        />
        <CancelPlanConfirmationDialog
          title={'Cancel Your Plan'}
          contentText={'Are you sure you want to cancel your plan? This action cannot be undone.'}
          handleAgree={cancelSubscriptionFunc}
        />
        <CancelWatchPlanConfirmationDialog
          title={'Cancel Your Watching Plan'}
          contentText={'Are you sure you want to cancel your plan? This action cannot be undone.'}
          handleAgree={cancelWatchSubscriptionFunc}
        />
        <section>
          <Box>
            <Box>
              <Box>
                <Box>
                  <div style={{ overflowX: 'auto' }}>
                    <Tabs
                      sx={{ mb: 2 }}
                      value={activeTab}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      centered={!isSmallScreen} // Centered tabs only on larger screens
                      variant={isSmallScreen ? 'scrollable' : 'standard'} // Use scrollable tabs on small screens
                      scrollButtons={isSmallScreen ? 'auto' : 'off'} // Show scroll buttons on small screens
                    >
                      <Tab label="Overview" />
                      <Tab label="Edit Profile" />
                      <Tab label="Change Password" />
                      <Tab label="Data & Privacy" />
                      {/* TODO fix CSS class not to use title case */}
                      <Tab label="Subscriptions" />
                    </Tabs>
                  </div>
                  <Box>
                    {activeTab === 0 && (
                      <Box id="profile-overview">
                        <Typography variant="h5" gutterBottom>
                          Profile Details
                        </Typography>

                        <Grid container>
                          <Grid item xs={5} sm={5} md={3}>
                            <Typography component="Box" sx={{ mr: 4, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              First Name:
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={8}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              {props.auth.user.firstName}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} sm={5} md={3}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              Last Name:
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={8}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              {props.auth.user.lastName}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} sm={5} md={3}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              Country:
                            </Typography>
                          </Grid>

                          <Grid item xs={6} sm={6} md={8}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              {props.auth.user.country ? props.auth.user.country : '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} sm={5} md={3}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              Address:
                            </Typography>
                          </Grid>

                          <Grid item xs={6} sm={6} md={8}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              {props.auth.user.address ? props.auth.user.address : '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} sm={5} md={3}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              City:
                            </Typography>
                          </Grid>

                          <Grid item xs={6} sm={6} md={8}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              {props.auth.user.city ? props.auth.user.city : '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} sm={5} md={3}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              State:
                            </Typography>
                          </Grid>

                          <Grid item xs={6} sm={6} md={8}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              {props.auth.user.state ? props.auth.user.state : '-'}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item xs={5} sm={5} md={3}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              Zip Code:
                            </Typography>
                          </Grid>

                          <Grid item xs={6} sm={6} md={8}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              {props.auth.user.zipCode ? props.auth.user.zipCode : '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} sm={5} md={3}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              Company:
                            </Typography>
                          </Grid>

                          <Grid item xs={6} sm={6} md={8}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              {props.auth.user.company ? props.auth.user.company : '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} sm={5} md={3}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              Phone:
                            </Typography>
                          </Grid>

                          <Grid item xs={6} sm={6} md={8}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              {props.auth.user.phone ? props.auth.user.phone : '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} sm={5} md={3}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              Email:
                            </Typography>
                          </Grid>

                          <Grid item xs={6} sm={6} md={8}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              {props.auth.user.email}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} sm={5} md={3}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              Website:
                            </Typography>
                          </Grid>

                          <Grid item xs={6} sm={6} md={8}>
                            <Typography component="Box" sx={{ mr: 2, fontSize: isSmallScreen ? '14px' : '17px' }}>
                              {props.auth.user.website ? props.auth.user.website : '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    )}

                    {activeTab === 1 && (
                      <Box id="profile-edit">
                        <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                          <TextField
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                            fullWidth
                            name="firstName"
                            type="text"
                            variant="outlined"
                            label="First Name"
                            id="name"
                            value={firstName}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                          <TextField
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                            fullWidth
                            name="lastName"
                            type="text"
                            variant="outlined"
                            label="Last Name"
                            id="name"
                            value={lastName}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                          <CountryDropdown style={styles.root} value={country} onChange={(val) => setCountry(val)} />
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                          <TextField
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                            fullWidth
                            name="address"
                            type="text"
                            variant="outlined"
                            label="Address"
                            id="Address"
                            value={address}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                          <TextField
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}
                            fullWidth
                            name="city"
                            type="text"
                            variant="outlined"
                            label="City"
                            id="City"
                            value={city}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                          <TextField
                            onChange={(e) => {
                              setState(e.target.value);
                            }}
                            fullWidth
                            name="state"
                            type="text"
                            variant="outlined"
                            label="State"
                            id="State"
                            value={state}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                          <TextField
                            onChange={(e) => {
                              setZipCode(e.target.value);
                            }}
                            fullWidth
                            name="zipCode"
                            type="text"
                            variant="outlined"
                            label="Zip Code"
                            id="zipCode"
                            value={zipCode}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                          <TextField
                            onChange={(e) => {
                              setCompany(e.target.value);
                            }}
                            fullWidth
                            name="company"
                            type="text"
                            variant="outlined"
                            label="Company"
                            id="company"
                            value={company}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                          <PhoneInput
                            fullWidth
                            inputStyle={{ width: '100%' }}
                            containerStyle={{ width: '100%' }}
                            country={'us'}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                          />
                        </Box>
                        {/* <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                            <TextField
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              fullWidth
                              name="email"
                              type="email"
                              variant="outlined"
                              label="Email"
                              id="Email"
                              value={email}
                            />
                          </Box> */}
                        <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                          <TextField
                            onChange={(e) => {
                              setWebsite(e.target.value);
                            }}
                            fullWidth
                            name="website"
                            type="website"
                            variant="outlined"
                            label="Website"
                            id="Website"
                            value={website}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                          <Button onClick={onUserUpdate} sx={{ mt: 2, mb: 2 }} variant="contained" color="primary">
                            Save Changes
                          </Button>
                        </Box>
                      </Box>
                    )}

                    {activeTab === 2 && (
                      <Box id="profile-change-password">
                        <TextField
                          onChange={(e) => {
                            setCurrentPassword(e.target.value);
                          }}
                          fullWidth
                          value={currentPassword}
                          name="password"
                          type="password"
                          variant="outlined"
                          label="Current Password"
                          id="currentPassword"
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                          fullWidth
                          value={newPassword}
                          name="newpassword"
                          type="password"
                          variant="outlined"
                          label="New Password"
                          id="newPassword"
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          onChange={(e) => {
                            setReEnterPassword(e.target.value);
                          }}
                          fullWidth
                          value={reEnterPassword}
                          name="renewpassword"
                          type="password"
                          variant="outlined"
                          label="Re-enter New Password"
                          id="renewPassword"
                          sx={{ mb: 2 }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                          <Button onClick={changePasswordFunc} sx={{ mt: 2, mb: 2 }} variant="contained" color="primary">
                            Change Password
                          </Button>
                        </Box>
                      </Box>
                    )}

                    {activeTab === 3 && (
                      <Box id="account">
                        <Typography variant="h4">Request Your Account Data</Typography>
                        Your account, your data. If you would like to obtain a copy of the content within your confusability.ai account for
                        backup purposes or to utilize it with a third-party service beyond confusability.ai, you can request your account
                        data here.
                        <Box>
                          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, mb: 2 }} onClick={setOpen}>
                            Request Data
                          </Button>
                        </Box>
                        <Typography variant="h4">Request Deletion of Your Account</Typography>
                        You have the option to delete your account at any time. Please keep in mind that account deletion is irreversible,
                        and once it's done, you won't be able to recover your account. When you delete your account, the following actions
                        occur: 1. Profile Information: We will remove all your profile information and any other content you have provided
                        within your profile. 2. Subscriptions and Purchased Content: You will lose access to any subscriptions and content
                        you have purchased using this account.
                        <Box>
                          <Button type="submit" variant="contained" color="secondary" sx={{ mt: 2, mb: 2 }} onClick={setOpenDeleteAccount}>
                            Delete Account
                          </Button>
                        </Box>
                      </Box>
                    )}
                    {activeTab === 4 && !loading ? (
                      <Box id="subscription">
                        {/* <Typography variant="span">Manage your subscription</Typography> */}
                        {/* 
                        {true && (
                          <Box>
                            <Typography variant="h4">Change Your Plan</Typography>
                            Choose between $9.99 per month and $99.99 per year.
                            <Box>
                              <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, mb: 2 }} onClick={onClickPremium}>
                                {!subscription ? 'Subscribe' : subscription.type.includes('yearly') ? 'Switch Your Plan To Monthly' : 'Switch Your Plan To Yearly' }
                              </Button>
                            </Box>
                          </Box>
                        )} */}

                        {!subscription ||
                          (!props.auth?.user?.subscription && (
                            <Box>
                              <Typography variant="h4">Change Your Plan</Typography>
                              Choose between $50 per month and $500 per year.
                              <Box>
                                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, mb: 2 }} onClick={onClickPremium}>
                                  Subscribe
                                </Button>
                              </Box>
                            </Box>
                          ))}
                        {props.auth?.user?.user?.subscription && subscription && !subscription?.cancelledByUser && (
                          <>
                            <Typography variant="h4">Cancel Your Search Subscription</Typography>
                            If you cancel your search plan, you will continue to have access to your account until the end of{' '}
                            {subscription?.isInTrial ? 'your free trial' : 'the current billing cycle'} on{' '}
                            {convertTimestampToLocalDate(props.auth?.user?.user?.subscription.expires)}.
                            <Box>
                              <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ mt: 2, mb: 2 }}
                                onClick={() => setOpenCancelPlan(true)}
                              >
                                Cancel Search Subscription
                              </Button>
                            </Box>
                          </>
                        )}
                        {(subscription?.cancelledByUser || locallyCancelledRequest) && (
                          <Box>
                            <Typography variant="h4">Nothing to do</Typography>
                            Your subscription has been successfully canceled, but you will retain access to the service until the end of
                            your current billing cycle. At that time, we will send you an email with options to renew your plan, should you
                            wish to do so. Thank you for being a valued member of our community!
                          </Box>
                        )}
                        {subscribedWatches.length > 0 && <Typography variant="h4">Cancel Your Watch Subscription</Typography>}
                        {subscribedWatches.map((data) => (
                          <>
                            <Box display={'flex'} mt={1}>
                              <Box xs={4} sm={3} md={2} lg={1} xl={1} mr={2}>
                                <SelectableCard notSelectable imageSrc={data.imageURL} onSelect={() => {}} />
                              </Box>
                              <Box xs={8} sm={9} md={10} lg={11} xl={11}>
                                {data?.subscriptionData?.cancelledByUser ? (
                                  <Box>
                                    <Info />
                                    {/* <Typography variant="h5">Nothing to do</Typography> */}

                                    <Typography variant="h7">
                                      {subscription?.isInTrial ? 'Your free trial' : 'The current billing cycle'} ends on{' '}
                                      {convertTimestampToLocalDate(data?.subscription.expires)}.
                                    </Typography>
                                    {/* <Typography variant="h7">
                                      Your subscription has been successfully canceled, but you will retain access to the service until the
                                      end of your current billing cycle. At that time, we will send you an email with options to renew your
                                      plan, should you wish to do so. Thank you for being a valued member of our community!{' '}
                                    </Typography> */}
                                  </Box>
                                ) : (
                                  <Box>
                                    <Typography component="div">
                                      {subscription?.isInTrial ? 'Your free trial' : 'The current billing cycle'} ends on{' '}
                                      {convertTimestampToLocalDate(data?.subscription.expires)}.
                                    </Typography>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      color="secondary"
                                      sx={{ mt: 2, mb: 2 }}
                                      onClick={() => setOpenCancelWatchPlan(data._id)}
                                    >
                                      Cancel Watch Subscription
                                    </Button>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </>
                        ))}
                      </Box>
                    ) : (
                      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'white' }} open={loading}>
                        <CircularProgress color="primary" sx={{ mr: 2 }} />
                        Loading...
                      </Backdrop>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </section>
      </MainCard>
      <Grid item xs={12} style={{ marginTop: 'auto' }}>
        <AuthFooter />
      </Grid>
    </Container>
  );
};

Profile.propTypes = {
  updateUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { updateUser })(withRouter(Profile));
const styles = {
  root: {
    border: '1px solid #cacaca',
    borderRadius: '5px',
    padding: 10,
    outline: 'none',
    width: '100%'
    // Add any other custom styles you want
  }
};
