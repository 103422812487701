// third-party
import { combineReducers } from 'redux';
import authReducers from 'store/auth/authReducers';
import errorReducers from 'store/auth/errorReducers';
import sessionReducer from 'store/sessionSlice';
// project import
import menu from './menu';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, auth: authReducers, errors: errorReducers, session: sessionReducer });

export default reducers;
