import { Box, FormControl, OutlinedInput, Typography } from '@mui/material';
import { useState } from 'react';

const TextInput = ({ placeholder, label, onChange, search }) => {
  const [text, setText] = useState('');

  const handleTextChange = (event) => {
    setText(event?.target?.value);
    onChange && onChange(event?.target?.value);
  };

  return (
    <Box sx={{ width: '100%', mr: 2, maxWidth: !search ? '300px' : '100%' }}>
      <Typography>{label}</Typography>
      <FormControl sx={{ width: '100%' }}>
        <OutlinedInput
          id="header-search"
          aria-describedby="header-search-text"
          inputProps={{
            'aria-label': 'weight'
          }}
          sx={{ width: 'auto', height: '40px' }}
          placeholder={placeholder ? placeholder : 'Type ...'}
          value={text} // Set the value of the input to the 'text' state
          onChange={handleTextChange} // Handle changes to the input value
        />
      </FormControl>
    </Box>
  );
};

export default TextInput;
