import axiosInstance from '../axiosInstance';

export const getApcByID = async (id) => {
  try {
    const response = await axiosInstance.post('/api/get-apc', { sn: id });
    // alert(JSON.stringify(response.data))
    return response.data;
  } catch (err) {
    return null;
  }
};
